import "./toolbar-notifications-pane.styl"
import template from "./toolbar-notifications-pane.pug"
import { router } from "@/lib/router"
import { DateUtils } from "@/lib/utils/date"
import { PageCursor } from "@/lib/utils/page-cursor"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { NotificationStore } from "@/stores/notification-store.core"

### Mediators ###
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"

### Models ###
import { Notification } from "@/models/notification"

### Popups ###
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Framework Includes ###
import ko from "knockout"

import { Notification as PopupNotification, Icons, notificationManagerInstance } from "@/lib/managers/notification-manager"

ITEMS_PER_REQUEST = 30

export class ToolbarNotificationsPane extends PopupPane
   constructor: () ->
      super(template())
      @unreadNotifications = ko.observableArray([])
      @paneMessage = ko.observable()

      @cursor = @getNewCursor()
      @currentRequest = ko.observable()

      @progressMediator = new PageableLoadingMediator () =>
         @refresh({skip: 0})

      @unreadNotificationsCount = ko.observable(0)

   getMetaString: (notification) ->
      createdAt = new Date(notification.createdAt())
      date = DateUtils.formatDate(createdAt)
      time = DateUtils.getTime(createdAt)
      return "#{notification.authorName()} - #{date} at #{time}"

   handleNotificationClick: (notification) =>
      try
         await NotificationStore.notificationViewed(notification.id).payload
      catch error
            notificationManagerInstance.show(
               new PopupNotification({
                  icon: Icons.WARNING,
                  text: "An unexpected error occurred.",
               }),
            );
            @dismissHandler();
            return console.log "Error: ", error;

      if notification.entity() == Notification.Entity.PERSON
         router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{notification.subject1Id()}")
      else if notification.entity() == Notification.Entity.PROJECT
         router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{notification.subject1Id()}")
      else if notification.entity() == Notification.Entity.ASSIGNMENT || notification.entity() == Notification.Entity.REQUEST         
         filterData = encodeURIComponent(JSON.stringify({
            value: notification.subject1Id()
            valueName: notification.subject1Name()
            filterName: "Project"
            property: "id"
         }))
         url = "/groups/#{authManager.selectedGroupId()}/gantt?notificationFilter=#{filterData}"
         if notification.meta()?.start_day?
            startDay = notification.meta().start_day
            url += "&dayFilter=#{startDay}"

         router.navigate(null, url)

      @dismissHandler()

   navigateHome: ->
      router.navigate(null, "/home")
      @dismissHandler()

   clearAllNotifications: ->
      try
         success = await NotificationStore.clearAllNotifications().payload
         if success
             @unreadNotifications([])
            @dismissHandler()
      catch error
         return console.log "Error: ", error

   getNewCursor: () =>
      return new PageCursor({
         cacheId: "nav-toolbar-notification-popup"
         defaultLimit: ITEMS_PER_REQUEST
         sizeOf: (data) => data.length
         loader: (params, callback) => 
            try
               notifications = await NotificationStore.getUnreadNotifications(params).payload;
               # TODO: Use notification-serializer instead of new Notiication
               data =  notifications.data.map (item) -> return new Notification(item);
               
               return callback(null, data)
            catch err
               console.log "Error: ", err
               return callback(err)
      })

   onReachedBottom: =>
      @loadBottomRecords()

   loadBottomRecords: () =>
      return if @currentRequest() or  !@cursor.hasMoreBottomRecords()
      @progressMediator.showLoader()
      @progressMediator.startProgress()

      @currentRequest @cursor.increment({}, (err, data) =>
         if err
            notificationManagerInstance.show(
               new PopupNotification({
                  icon: Icons.WARNING,
                  text: "An error occurred while loading unread notifications.",
               }),
            );
            @title("An unexpected error occurred");
            @paneMessage("An unexpected error occurred");
            return;
         if data.length == 0
            @paneMessage("You're all caught up! ")

         @progressMediator.appendData =>
            @currentRequest(null)
            ko.utils.arrayPushAll(@unreadNotifications, data)
            @title("#{@unreadNotificationsCount()} Unread Notifications")
      )

   refresh: (overrides = {}) =>
      if @currentRequest()?
         @currentRequest().cancel()
         @currentRequest(null)

      @cursor.startingIndex(overrides.skip) if overrides.skip?

      try
         notificationData = await NotificationStore.getUnreadNotificationsCount().payload
         count = notificationData.data.unread_notification_count

         @unreadNotificationsCount(count)
         @title("#{@unreadNotificationsCount()} Unread Notifications")
      catch error
         notificationManagerInstance.show(
               new PopupNotification({
                  icon: Icons.WARNING,
                  text: "An error occurred while counting unread notifications.",
               }),
            );
         @unreadNotificationsCount(0)
         return console.log "Error: ", error

      @unreadNotifications([])
      @loadBottomRecords(overrides)
      
