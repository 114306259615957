import type { Observable } from "knockout";
import { computed, observable, observableArray } from "knockout";
import type { GridStore, RowBase } from "./grid-store";
import { LoadingState } from "./grid-store";
import { cacheManager } from "@/lib/managers/cache-manager";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";
import type { GridColumnManager } from "@/lib/components/grid/grid-column-manager";

export enum LoadType {
   INITIAL = "initial",
   NEXT = "next",
   PREVIOUS = "previous",
}

export type ErrorCallback = (error: Error, loadingState: LoadingState) => void;

export interface PaginatedGridStoreParams<QueryParams, Cursor> {
   queryParams: QueryParams;
   cacheKey?: string | null;
   startingCursor?: Cursor | null;
   startFromCurrentCursor?: boolean;
   onLoadError?: ErrorCallback | null;
   columnManager?: Observable<GridColumnManager<any> | null>;
}

/** Abstract store for working with paginated endpoints. */
export abstract class PaginatedGridStore<Row extends RowBase, QueryParams, Cursor>
   implements GridStore<Row>
{
   private readonly queryParams: QueryParams;
   private readonly cacheKey: string | null;
   private readonly onLoadError: ErrorCallback | null;
   private readonly columnManager: Observable<GridColumnManager<any> | null>;
   private initialRowsLoadComplete = false;
   private cursor: Cursor | null = null;

   readonly rows = observableArray<Row>();
   readonly loadingState = observable(LoadingState.NONE);
   readonly hasPreviousRows = observable(false);
   readonly hasNextRows = observable(true);
   readonly error = observable<string | null>(null);

   // NOTE: This is actually managed by the subclasses.
   readonly isLoadingAll = observable(false);

   constructor({
      queryParams,
      cacheKey = null,
      startingCursor = null,
      startFromCurrentCursor = false,
      onLoadError = null,
      columnManager,
   }: PaginatedGridStoreParams<QueryParams, Cursor>) {
      this.queryParams = queryParams;
      this.cacheKey = cacheKey;
      this.onLoadError = onLoadError;
      if (startingCursor) {
         if (this.cacheKey) cacheManager.add(this.cacheKey, startingCursor);
         this.cursor = startingCursor;
      } else if (startFromCurrentCursor && this.cacheKey && cacheManager.get(this.cacheKey)) {
         this.cursor = cacheManager.get(this.cacheKey) as Cursor | null;
      }

      // The columnManager param was introduced so that the list will not appear as loaded until we've guaranteed that the columnHeaders have been loaded by the getColumnHeaders endpoint.
      // See bugfix WFP-1914 for more details.
      this.columnManager = columnManager ?? observable(null);
      // Because this computed is only listening to the columnManager().loadingState() observables, it will execute once the loading state of the column manager changes, and then no more thereafter.
      computed(() => {
         if (this.columnManager() != null) {
            const columnHeadersHaveLoaded = this.columnManager()!.loadingState() == "loaded";

            if (this.initialRowsLoadComplete && columnHeadersHaveLoaded) {
               this.loadingState(LoadingState.NONE);
            }
         }
      });
   }

   /** Subclasses implement this method to load data. */
   protected abstract loadRowsForType(
      loadType: LoadType,
      queryParams: QueryParams,
   ): Row[] | Promise<Row[]>;

   /** Subclasses implement this method to create the cursor for the give row. */
   protected abstract createCursor(rowIndex: number): Cursor;

   getCursor(): Cursor | null {
      return this.cursor;
   }

   getQueryParams(): QueryParams {
      return this.queryParams;
   }

   setFirstVisibleRow(row: Row): void {
      if (!this.cacheKey) return;
      const rows = this.rows();
      const index = rows.findIndex((r) => r.id == row.id);
      if (index < 1) {
         if (index == -1) console.warn(`Row is unknown:`, row);
         cacheManager.remove(this.cacheKey);
      } else {
         cacheManager.add(this.cacheKey, this.createCursor(index));
      }
   }

   abstract loadAll(): void;

   abstract cancelLoadAll(): void;

   loadInitialRows(): void {
      this.loadRowsInternal(LoadType.INITIAL);
   }

   loadPreviousRows(): void {
      this.loadRowsInternal(LoadType.PREVIOUS);
   }

   loadNextRows(): void {
      this.loadRowsInternal(LoadType.NEXT);
   }

   private loadRowsInternal(loadType: LoadType) {
      if (this.isLoadingAll() || this.loadingState() != LoadingState.NONE) return;
      try {
         const result = this.loadRowsForType(loadType, this.queryParams);
         const onSuccess = (rows: Row[]) => {
            if (loadType == LoadType.PREVIOUS) {
               this.rows.unshift(...rows);
            } else {
               this.rows.push(...rows);
            }
            // This only has potential to be falsy on the first load, but once the column headers have loaded, this will remain truthy for subsequent pagination calls.
            if (this.columnManager() == null || this.columnManager()!.loadingState() == "loaded") {
               this.loadingState(LoadingState.NONE);
            }
            this.initialRowsLoadComplete = true;
         };
         if (result instanceof Promise) {
            this.loadingState(this.loadingStateFromLoadType(loadType));
            result.then(onSuccess).catch(this.handleError);
         } else {
            onSuccess(result);
         }
      } catch (error) {
         this.handleError(error as Error);
      }
   }

   private handleError = (wrappedError: Error) => {
      const error = this.unwrapError(wrappedError);
      Bugsnag.notify(error as NotifiableError, (event) => {
         event.context = "paginated-grid-store_handleError";
         event.addMetadata(
            BUGSNAG_META_TAB.USER_DATA,
            buildUserData(authManager.authedUser()!, authManager.activePermission),
         );
      });
      const loadingState = this.loadingState();
      this.loadingState(LoadingState.NONE);
      this.error("Unexpected error occurred");
      if (this.onLoadError) {
         this.onLoadError(error, loadingState);
      }
   };

   private unwrapError = (error: any, recursionCount = 0): Error => {
      if (recursionCount > 10) {
         return new Error("Unknown PaginatedGridStore error: " + JSON.stringify(error));
      }
      if (error instanceof Error) {
         return error;
      } else if (error.error != null) {
         return this.unwrapError(error.error, recursionCount + 1);
      } else {
         return new Error("Unknown PaginatedGridStore error: " + JSON.stringify(error));
      }
   };

   private loadingStateFromLoadType(loadType: LoadType): LoadingState {
      switch (loadType) {
         case LoadType.INITIAL:
            return LoadingState.INITIAL;
         case LoadType.NEXT:
            return LoadingState.NEXT;
         case LoadType.PREVIOUS:
            return LoadingState.PREVIOUS;
      }
   }
}
