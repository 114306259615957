window.liveagent = window.liveagent ?? {};
window.liveagent.isOnline = null;

export const CHAT_ID = "5738V0000008TrQ";

export const LIVEAGENT_ONLINE_ID = `liveagent_button_online_${CHAT_ID}`;
export const LIVEAGENT_OFFLINE_ID = `liveagent_button_offline_${CHAT_ID}`;

/**
 * Prerequisites:
 * - An online-state element exists with id: `liveagent_button_online_${CHAT_ID}`
 *   - Exported as: LIVEAGENT_ONLINE_ID
 * - An offline-state element exists with id: `liveagent_button_offline_${CHAT_ID}`
 *   - Exported as: LIVEAGENT_OFFLINE_ID
 *
 * Returns a void Promsie when intialization is complete.
 */
export function initializeLiveChat() {
    const element = document.createElement('script');

    element.src = 'https://c.la5-c1-ia5.salesforceliveagent.com/content/g/js/55.0/deployment.js';
    element.type = 'text/javascript';
    element.async = true;

    document.head.appendChild(element);

    if (!window._laq) { window._laq = []; }
    window._laq.push(function () {
        window.liveagent.showWhenOnline(CHAT_ID, document.getElementById(LIVEAGENT_ONLINE_ID));
        window.liveagent.showWhenOffline(CHAT_ID, document.getElementById(LIVEAGENT_OFFLINE_ID));
    });

    return new Promise((resolve) => {
        element.onload = () => {
            /**
             * Salesforce's Live Chat feature doesn't directly expose the online/offline state.
             * Instead, it expects to find elements on the page with specified id values,
             * which the vendor script will directly manipulate the disply values of.
             *
             * The following code watches for those display value manipulations and captures
             * them into a local state management tracker for use throughout the app.
             */
            
            setIsOnline(true);
            window.liveagent.init('https://d.la5-c1-ia5.salesforceliveagent.com/chat', '572340000008OaS', '00D300000000QCK');
            resolve();
        }
      });
}

/**
 * Opens the chat window if chat is online.
 */
export function startLiveSupportChat() {
    if (window.liveagent.isOnline) {
        window.liveagent.startChat(CHAT_ID);
    }
}

/**
 * Represents the current online/offline state of salesforce livechat.
 */
export let isLiveChatOnline = window.liveagent.isOnline;

let isOnlineCallback = null;
/**
 * @param {(isOnline: boolean) => void} callback
 */
export const setIsOnlineCallback = (callback) => {
    isOnlineCallback = callback;
}

function setIsOnline(isOnline) {
    window.liveagent.isOnline = isOnline;
    isLiveChatOnline = isOnline;
    if (isOnlineCallback != null) {
        isOnlineCallback(isOnline);
    }
}
