import "./segmented-controller.styl"
import segmentedControllerTemplateFn from "./segmented-controller.pug"

### Framework Includes ###
import ko from "knockout"

export class SegmentedControllerItem
   constructor: (name, value, buttonClass) ->
      assertArgs(arguments, [String, Function], anything(), optional(String))

      if name instanceof Function
         assertOfType(name(), String)
         @name = name
      else
         @name = ko.observable(name)

      if value instanceof Function
         @value = value
      else
         @value = ko.observable(value)

      @btnClass = buttonClass or ''

export class SegmentedController
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.selectionOptions, [Array, Function])
      assertOfType(params.selectedValue, optional(Function))
      assertOfType(params.selectedRawValue, optional(Function))
      assertOfType(params.onChanged, optional(Function))

      @selectedValue = ko.observable(null)
      @selectedRawValue = ko.observable(null)
      @usingRawValue = ko.observable(false)
      @itemWidth = ko.pureComputed =>
         percentage = (100 / (@options().length))
         return "#{percentage}%"

      if params.selectionOptions instanceof Function
         @options = params.selectionOptions
      else
         @options = ko.observableArray(params.selectionOptions)

      if params.selectedValue instanceof Function
         @selectedValue = params.selectedValue
      else if params.selectedRawValue instanceof Function
         # This allows us to only send back the value, not the item instance. 
         @selectedRawValue = params.selectedRawValue
         @usingRawValue(true)

      @onChanged = if params.onChanged? then params.onChanged else null

      @isCta = if params.isCta? and params.isCta then ko.observable(true) else ko.observable(false)

      @disabled = params.disabled or ko.observable(false)

   selectSegment: (data) =>
      return if @disabled()
      if @usingRawValue()
         @selectedRawValue(data.value())
      else
         @selectedValue(data)

      @onChanged(data.value()) if @onChanged?

segmentedControllerTemplate = segmentedControllerTemplateFn()

ko.components.register("segmented-controller",
   viewModel: SegmentedController,
   template: segmentedControllerTemplate
)
