export interface EventVisitorResult {
   allowPropagation?: boolean;
}

export interface EventVisitor<T extends Event> {
   visit: (event: T) => boolean;
   accept: (event: T) => EventVisitorResult | void;
}

export class EventHandler<T extends Event> {
   constructor(private readonly visitors: Array<EventVisitor<T>>) {}

   /**
    * Passes the event through each visitor until an accepting visitor is found.
    * Returns `true` when handled and fall otherwise.
    */
   handle(event: T): boolean {
      for (const visitor of this.visitors) {
         if (visitor.visit(event)) {
            const result = visitor.accept(event);
            if (!result || !result.allowPropagation) {
               event.preventDefault();
               event.stopPropagation();
            }
            return true;
         }
      }
      return false;
   }
}
