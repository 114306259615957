import { cacheManager } from "@/lib/managers/cache-manager"

export class PageCursor
   constructor: ({cacheId, defaultLimit, loader, sizeOf}) ->
      @cacheId_ = cacheId
      @loader_ = loader
      @defaultLimit_ = defaultLimit
      @sizeOf_ = sizeOf

      @startingIndex = ko.observable(cacheManager.get(@cacheId_, { lastIndex: 0 }).lastIndex);
      # We must set this always notify for when users want to reset the cursor to zero on a new search. 
      @startingIndex.extend({ notify: 'always' })
      @topIndex = ko.observable(@startingIndex())
      @bottomIndex = ko.observable(@startingIndex())
      @lastIndex = ko.observable(@startingIndex())
      @hasMoreTopRecords = ko.pureComputed () => @topIndex() > 0
      @hasMoreBottomRecords = ko.observable(true)
      
      
      @startingIndexResetSubscription_ = @startingIndex.subscribe (val) =>
         # If val is ever reset to zero that is interpreted as a 'New Search' 
            # Thus we should drop the cache ID and let the user initiate another cache save. 
         if val == 0 
            cacheManager.remove(@cacheId_)
         @topIndex(val)
         @bottomIndex(val)
         @lastIndex(val)
         @hasMoreBottomRecords(true)

      @lastIndexChangeSubscription_ = @lastIndex.subscribe (val) => 
         cacheManager.add(@cacheId_, { lastIndex: @topIndex() + val }) if @cacheId_?

                  
   decrement: (query, callback) =>
      limit = @defaultLimit_
      if @topIndex() > @defaultLimit_
         @topIndex(@topIndex() - @defaultLimit_)
      else 
         limit = @topIndex()
         @topIndex(0)
         
      newQuery = Object.assign({
         skip: @topIndex(), 
         limit: limit
      }, query)
         
      return @loader_(newQuery, callback)
      
   increment: (query, callback) =>
      newQuery = Object.assign({
         skip: @bottomIndex(),
         limit: @defaultLimit_
      }, query)
      return @loader_ newQuery, (err, data) =>
         if !err
            count = @sizeOf_(data)
            @hasMoreBottomRecords(count >= @defaultLimit_)
            @bottomIndex(@bottomIndex() + count)
         callback(err, data)
            
   save: (index) => @lastIndex(index)
   
   dispose: () => 
      @startingIndexResetSubscription_.dispose()
      @lastIndexChangeSubscription_.dispose()
