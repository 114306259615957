import "./modal.styl"
import template from "./modal.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { ObservableData } from "@/lib/utils/observable-data"
import { ViewModel } from "@/lib/vm/viewmodel"

export class Modal extends ViewModel
   constructor: ->
      super(template())
      @modalManager = null
      @isShowing = ko.observable(false)
      @panes = ko.observableArray()
      @activePaneIndex = ko.observable(0)
      @activePane = ko.pureComputed => return @panes()[@activePaneIndex()]
      @disableHeader = @createComputedForProperty_("disableHeader")
      @title = @createComputedForProperty_("title")
      @negationText = @createComputedForProperty_("negationText")
      @actionText = @createComputedForProperty_("actionText")
      @isActionEnabled = @createComputedForProperty_("isActionEnabled")
      @hasSinglePane = ko.pureComputed => return @panes().length == 1
      @data = null
      @isEditing = @createComputedForProperty_("isEditing")
      @rightHeaderBtn = @createComputedForProperty_("rightHeaderBtn")
      @displayingNotice = @createComputedForProperty_("displayingNotice")
      @overflowable = @createComputedForProperty_("overflowable")

      @backBtnText = @createComputedForProperty_("backBtnText")
      @backwardEnabled = @createComputedForProperty_("backwardEnabled")
      @nextBtnText = @createComputedForProperty_("nextBtnText")
      @nextBtnDisabledText = @createComputedForProperty_("nextBtnDisabledText")
      @forwardEnabled = @createComputedForProperty_("forwardEnabled")

   initialize: (modalManager, data) ->
      # Have to use it here to avoid circular dependency.
      @modalManager = modalManager
      @data = data
      @tryInitializingPanes_()

   ###
    * Called by modal manager when the modal will become visible (before starting animation).
   ###
   willBecomeVisible: ->
      @isShowing(true)

   ### Called by modal manager when the modal has become visible. ###
   hasBecomeVisible: ->

   ###
    * Called by modal manager when the modal will no longer be visible (before starting
    * animation).
   ###
   willBecomeHidden: ->

   ### Called by modal manager when the modal is no longer visible. ###
   hasBecomeHidden: ->
      @isShowing(false)

   setPanes: (panes) ->
      assertArgs(arguments, arrayOf(ModalPane))
      @panes(panes)
      @tryInitializingPanes_()

   cancelBtnPressed: ->
      @modalManager.modalCancelled()

   actionBtnClicked: ->
      return unless @isActionEnabled()
      paneIndex = @activePaneIndex()
      completeAction = =>
         @panes()[paneIndex].transitionedFrom("forward")
         @modalManager.modalFinished()
      if @panes()[paneIndex].actionBtnIntercept?
         @panes()[paneIndex].actionBtnIntercept(completeAction)
      else
         completeAction()

   nextBtnClicked: ->
      paneIndex = @activePaneIndex()
      @panes()[paneIndex].transitionedFrom("forward")
      if paneIndex + 1 < @panes().length
         @activePaneIndex(paneIndex + 1)
         @panes()[paneIndex + 1].transitionedTo("forward")

   backBtnClicked: ->
      paneIndex = @activePaneIndex()
      @panes()[paneIndex].transitionedFrom("back")
      if paneIndex != 0
         @activePaneIndex(paneIndex - 1)
         @panes()[paneIndex - 1].transitionedTo("back")

   createComputedForProperty_: (propertyName) =>
      assertArgs(arguments, String)
      return ko.pureComputed =>
         active = @activePane()
         return if active then active[propertyName]?() else null

   tryInitializingPanes_:  ->
      if @data and @panes().length > 0
         pane.initialize(@, @data) for pane in @panes()

   dismissNotice: =>
      @panes()[@activePaneIndex()].displayingNotice(null)
