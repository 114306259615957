import { Store } from "@/stores/common/store.core";
import type { StoreJsonResponse } from "@/stores/common/store.core";
import type {
   GetNotificationsRequest,
   GetNotificationsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notifications/get-user-notifications";
import type { GetUserUnreadNotificationsCountResponse } from "@laborchart-modules/lc-core-api/dist/api/notifications/get-user-notifications-count";
import type {
   NotificationViewedRequest,
   NotificationViewedResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notifications/notification-viewed";
import type { ClearNotificationsResponse } from "@laborchart-modules/lc-core-api/dist/api/notifications/clear-notifications";
export abstract class NotificationStore extends Store {
   static getNotifications(
      params: GetNotificationsRequest,
   ): StoreJsonResponse<GetNotificationsResponse> {
      return this.requestJson({
         url: `/api/v3/notifications/user-notifications`,
         method: "GET",
         query: params,
      });
   }

   static getUnreadNotifications(
      params: GetNotificationsRequest,
   ): StoreJsonResponse<GetNotificationsResponse> {
      return this.requestJson({
         url: `/api/v3/notifications/user-unread-notifications`,
         method: "GET",
         query: params,
      });
   }

   static getUnreadNotificationsCount(): StoreJsonResponse<GetUserUnreadNotificationsCountResponse> {
      return this.requestJson({
         url: `/api/v3/notifications/user-unread-notifications-count`,
         method: "GET",
      });
   }

   static notificationViewed(
      notificationId: NotificationViewedRequest,
   ): StoreJsonResponse<NotificationViewedResponse> {
      return this.requestJson({
         url: `/api/v3/notifications/${notificationId}/viewed`,
         method: "PATCH",
      });
   }

   static clearAllNotifications(): StoreJsonResponse<ClearNotificationsResponse> {
      return this.requestJson({
         url: `/api/v3/notifications/clear-unread-notifications`,
         method: "PATCH",
      });
   }
}
