import type { Action } from "@/lib/managers/notification-manager";
import { Icons, Notification } from "@/lib/managers/notification-manager";

interface ProgressDelta {
   timestamp: number;
   percent: number;
   elapsedTime: number;
   percentChanged: number;
}

const DELTAS_FOR_ESTIMATE = 3;

export class ProgressNotification extends Notification {
   private message: string;
   /** Percent complete as a decimal. */
   private percent: number | null = null;
   private progressDeltas: ProgressDelta[] = [];

   constructor({ message, actions }: { message: string; actions?: Action[] }) {
      super({
         icon: Icons.PENDING,
         text: ProgressNotification.getText({ message, percent: null, secondsRemaining: null }),
         actions,
      });
      this.message = message;
   }

   update(update: { message?: string; percent?: number | null; actions?: Action[] }): void {
      if (update.message) {
         this.message = update.message;
      }
      if (update.percent) {
         this.percent = update.percent;
         this.capturePercentDelta();
      }
      this.text(
         ProgressNotification.getText({
            message: this.message,
            percent: this.percent,
            secondsRemaining: this.calculateSecondsRemaining(),
         }),
      );
      this.icon(Icons.PENDING);
   }

   success(update: { message: string; actions?: Action[] }): void {
      this.message = update.message;
      this.percent = null;
      this.icon(Icons.SUCCESS);
      this.text(this.message);
      if (update.actions) this.actions(update.actions);
   }

   failed(update: { message: string; actions?: Action[] }): void {
      this.message = update.message;
      this.percent = null;
      this.icon(Icons.WARNING);
      this.text(this.message);
      if (update.actions) this.actions(update.actions);
   }

   private capturePercentDelta() {
      if (this.percent == null) {
         if (this.progressDeltas.length) {
            this.progressDeltas = [];
         }
      } else {
         const now = Date.now();
         const previous =
            this.progressDeltas.length > 0
               ? this.progressDeltas[this.progressDeltas.length - 1]
               : null;
         // Only record after we've changed at least a percentage point and half a second
         // to avoid a flood of rapid changes messing up the calculations.
         if (
            !previous ||
            (this.percent - previous.percent >= 0.01 && now - previous.timestamp >= 500)
         ) {
            this.progressDeltas.push({
               timestamp: now,
               percent: this.percent,
               elapsedTime: previous ? now - previous.timestamp : 0,
               percentChanged: previous ? this.percent - previous.percent : 0,
            });
            if (this.progressDeltas.length > DELTAS_FOR_ESTIMATE) {
               this.progressDeltas.shift();
            }
         }
      }
   }

   private calculateSecondsRemaining() {
      if (this.percent == null) return null;
      if (this.progressDeltas.length < DELTAS_FOR_ESTIMATE) return null;
      if (this.progressDeltas.some((p) => p.percentChanged <= 0)) return null;

      const averageMillisPerOnePercent =
         this.progressDeltas
            .map((delta) => {
               const percentAsInteger = delta.percentChanged * 100;
               return delta.elapsedTime / percentAsInteger;
            })
            .reduce((acc, value) => acc + value, 0) / this.progressDeltas.length;

      const remainingPercent = (1 - this.percent) * 100;
      return Math.ceil((remainingPercent * averageMillisPerOnePercent) / 1000);
   }

   private static getText({
      message,
      percent,
      secondsRemaining,
   }: {
      message: string;
      percent: number | null;
      secondsRemaining: number | null;
   }) {
      if (percent == null || percent >= 1) {
         return message;
      }
      const percentAsInt = Math.floor(percent * 100);
      return secondsRemaining != null
         ? `${message} ${percentAsInt}% (${this.formatSeconds(secondsRemaining)})`
         : `${message} ${percentAsInt}%`;
   }

   private static formatSeconds(seconds: number) {
      if (seconds < 30) return "less than 30 seconds";
      if (seconds < 45) return "less than a minute";
      if (seconds < 90) return "1 minute";
      return `${Math.ceil(seconds / 60)} minutes`;
   }
}
