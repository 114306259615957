import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import type { SerializedPerson } from "@laborchart-modules/common/dist/rethink/serializers/person-serializer";
import { PersonDropDownPane } from "../../drop-downs/panes/person-drop-down-pane";
import { CheckboxCell } from "../../grid/cells/checkbox-cell";
import type { PureComputed } from "knockout";
import { pureComputed } from "knockout";
import type { DropDown2Params } from "../../drop-downs/drop-down-2";
import { ActionResult } from "../../drop-downs/drop-down-2";
import { authManager } from "@/lib/managers/auth-manager";

export class SaveViewPane2 {
   loadPeopleOptions({
      limitedGroupIds,
      onChange,
      selectedPeopleIds,
   }: {
      limitedGroupIds: string[] | null;
      onChange: (ids: Set<string>) => void;
      selectedPeopleIds: PureComputed<Set<string>>;
   }): DropDown2Params<SerializedPerson> {
      const personPane = PersonDropDownPane.create({
         groupIds: new Set(limitedGroupIds),
         isUser: true,
         status: PersonStatus.ACTIVE,
      });
      const personCellFactory = CheckboxCell.factory<SerializedPerson>((person) => {
         return {
            alignment: "left",
            title: authManager.authedUser()!.preferences()?.displayLastNamesFirst()
               ? `${person.name.last}, ${person.name.first}`
               : `${person.name.first} ${person.name.last}`,
            value: pureComputed(() => selectedPeopleIds().has(person.id)),
         };
      });

      const personDropDownParams: DropDown2Params<SerializedPerson> = {
         isInvertible: true,
         actionInterceptor: () => ActionResult.TOGGLE,
         cellFactory: personCellFactory,
         panes: [personPane as any],
         placeholder: "Select Person...",
         selectedIds: pureComputed({
            read: () => {
               return selectedPeopleIds();
            },
            write: (ids: Set<string>) => {
               onChange(ids);
            },
         }),
      };
      return personDropDownParams;
   }
}
