import { Dom } from "@/lib/utils/dom"
import { ObservableData } from "@/lib/utils/observable-data"
import $ from "jquery"
import ko from "knockout"

export class ModalManager
   constructor: ->
      @activeModal = null
      @callback = null
      @modalData = null
      @$cover_ = null
      @$modal_ = null
      @$modalBox_ = null
      @activeModalClass = ko.observable()
      @animatingModalIn = ko.observable(false)

   initialize: (targetElement = "body") ->
      @$cover_ = $(ModalManager.COVER_HTML)
      @$cover_.hide()
      @$modal_ = $(ModalManager.MODAL_HTML)
      @$modalBox_ = @$modal_.children().first()
      @$modal_.hide()
      $(targetElement).append(@$cover_, @$modal_)

   showModal: (modal, data, options, callback) ->
      assertArgs(arguments, Object, optional(Object), optional(Object), optional(Function))
      throw Error("ModalManager has not been initialize") unless @$cover_
      throw Error("A modal is already visible") if @activeModal
      @modalData = new ObservableData(data or {})
      @callback = callback or ->

      # Create modal from panes.
      @activeModal = modal

      @activeModal.initialize(@, @modalData)
      @bindModal_(@activeModal)

      # Handle options.
      @activeModalClass(options.class) if options?.class?

      @activeModal.willBecomeVisible()
      @animatingModalIn(true)
      @animateInModal_ =>
         @animatingModalIn(false)
         @activeModal.hasBecomeVisible() if @activeModal?

   ### Called by a modal to indicate that the user cancelled the modal. ###
   modalCancelled: =>
      @closeModal_("cancelled")

   ### Called by a modal to indicate that the user finished the modal. ###
   modalFinished: =>
      @closeModal_("finished")

   maybeCancelModal: (done) =>
      return done() unless @activeModal?
      @activeModal.willBecomeHidden() if @activeModal?
      @animateOutModal_ =>
         @$modal_.css({display: "none"})
         @$cover_.css({display: "none"})
         @activeModalClass("")
         @activeModal.hasBecomeHidden() if @activeModal?
         @unbindModal_()
         modal = @activeModal
         @activeModal = null
         @callback(modal, status, @modalData)
         done()

   closeModal_: (status) =>
      assertArgs(arguments, String)
      @activeModal.willBecomeHidden() if @activeModal?
      @animateOutModal_ =>
         @$modal_.css({display: "none"})
         @$cover_.css({display: "none"})
         @activeModalClass("")
         @activeModal.hasBecomeHidden() if @activeModal?
         @unbindModal_()
         modal = @activeModal
         @activeModal = null
         @callback(modal, status, @modalData)

   bindModal_: (modal) ->
      assertArgs(arguments, Object)
      content = Dom.getTemplateString(modal.template())
      throw Error("Couldn't find template: #{modal.template()}") unless content
      @$modalBox_.append(content)
      ko.applyBindings(modal, @$modalBox_[0])

   unbindModal_: ->
      ko.cleanNode(@$modalBox_[0])
      @$modalBox_.empty()

   animateInModal_: (done) ->
      assertArgs(arguments, Function)
      @$modal_.one "transitionend", done
      @$modal_.show()
      @$cover_.show()
      @$cover_.css({opacity: 1})
      @$modal_.css({opacity: 1})

   animateOutModal_: (done) ->
      assertArgs(arguments, Function)
      # To catch when a modal hasn't had time to fully animate in yet.
      return done() if @activeModal? and @animatingModalIn
      @$modal_.one "transitionend", =>
         @$modal_.hide()
         done()
      @$cover_.one "transitionend", =>
         # This is blocking hiding cover when another modal gets added before transition is over.
         unless @activeModal?
            @$cover_.hide()
      @$modal_.css({opacity: 0})
      @$cover_.css({opacity: 0})

ModalManager.COVER_HTML = "<div class=\"modal__cover\"></div>"
ModalManager.MODAL_HTML = '<div class="modal" data-bind="css: activeModalClass"><div class="modal__box"></div></div>'
ModalManager.PADDING = 10


export modalManager = new ModalManager()