import type { Observable, ObservableArray } from "knockout";

/** Represent the base interface all rows must extend from. */
export interface RowBase {
   id: string;
}

export enum LoadingState {
   NONE = "none",
   INITIAL = "initial",
   PREVIOUS = "previous",
   NEXT = "next",
}

/** Store acting as a data source for a grid. */
export interface GridStore<Row extends RowBase> {
   /**
    * Returns an observable array containing the rows loaded by this store.
    * NOTE: External code should NEVER update this observable.
    */
   readonly rows: ObservableArray<Row>;

   /**
    * Returns an observable of the loading state.
    * NOTE: External code should NEVER update this observable.
    */
   readonly loadingState: Observable<LoadingState>;

   /**
    * Whether additional previous rows can be loaded.
    * NOTE: External code should NEVER update this observable.
    */
   readonly hasPreviousRows: Observable<boolean>;

   /**
    * Whether additional next rows can be loaded.
    * NOTE: External code should NEVER update this observable.
    */
   readonly hasNextRows: Observable<boolean>;

   /** Error message generated while loading the rows. */
   readonly error?: Observable<string | null>;

   /**
    * Sets the first visible row.
    * Implementations should use this as a trigger to store the user's current scroll position.
    */
   setFirstVisibleRow(row: Row): void;

   /**
    * Loads the initial set of rows and updates the `rows` observable.
    * NOTE: This may be from the user's saved scroll position.
    */
   loadInitialRows(): void;

   /** Loads the previous set of rows and updates the `rows` observable. */
   loadPreviousRows(): void;

   /** Loads the next set of rows and updates the `rows` observable. */
   loadNextRows(): void;
}
