import "./drop-down-2-pane.styl";
import template from "./drop-down-2-pane.pug";
import type {
   Computed,
   MaybeObservable,
   Observable,
   ObservableArray,
   Subscribable,
   Subscription,
} from "knockout";
import ko, { observable, observableArray, pureComputed, unwrap } from "knockout";
import type { DropDownPane, ItemBase } from "./drop-down-pane";
import type { GridColumnGroup } from "../grid/grid-column-group";
import type { GridAction, GridCellFocus } from "@/lib/components/grid/virtual-grid/virtual-grid";
import { GridActionType } from "@/lib/components/grid/virtual-grid/virtual-grid";
import type { GridCellFactory } from "../grid/grid-column";
import type { ComponentArgs } from "../common";
import { isEqualSets } from "@/lib/utils/sets";
import { EventHandler } from "@/lib/utils/event-handler";

export interface DropDown2PaneParams<T extends ItemBase> {
   pane: MaybeObservable<DropDownPane<T>>;
   cellFactory: GridCellFactory<T>;
   maxHeight: Observable<number>;
   selectedIds: Observable<Set<string>>;
   isVisible: Subscribable<boolean>;
   hasBackButton: Computed<boolean>;
   containsFocus: Observable<boolean>;
   onSelectItem: (item: T) => void;
   onBack: () => void;
   onBeforeContentChange: () => void;
}

const SEARCH_BAR_HEIGHT = 25;
const SHADOW_HEIGHT = 1;

export class DropDown2Pane<T extends ItemBase> {
   readonly pane: DropDownPane<T>;
   readonly selectedIds: Observable<Set<string>>;
   readonly hasBackButton: Computed<boolean>;
   readonly containsFocus: Observable<boolean>;
   readonly onBack: () => void;

   readonly search = observable<string | null>(null);
   readonly delayedSearch = pureComputed(() => {
      return this.search();
   }).extend({
      rateLimit: {
         method: "notifyWhenChangesStop",
         timeout: 300,
      },
   });
   readonly isSearchFocused = observable(false);
   readonly gridMaxHeight = pureComputed(() => {
      const searchHeight = unwrap(this.pane.isSearchable || false) ? SEARCH_BAR_HEIGHT : 0;
      return this.maxHeight() - searchHeight - SHADOW_HEIGHT;
   });

   readonly gridResizeTrigger = observable(0);
   readonly cellFocus = observable<GridCellFocus>(null);
   readonly firstVisibleRowId = observable<string | null>(null);

   readonly columnGroups = pureComputed<Array<GridColumnGroup<T>>>(() => {
      return [
         {
            columns: [
               {
                  key: "drop-down",
                  header: "",
                  width: 50,
                  cellFactory: this.cellFactory,
                  autoResizable: true,
               },
            ],
         },
      ];
   });

   readonly gridSelectedIds: ObservableArray<string>;
   private readonly maxHeight: Observable<number>;
   private readonly onBeforeContentChange: () => void;
   private readonly cellFactory: GridCellFactory<T>;
   private readonly onSelectItem: (item: T) => void;
   private readonly subscriptions: Subscription[] = [];
   private hasBeenVisible = false;
   private waitingToScrollToSelectedRow = false;

   constructor({
      pane,
      cellFactory,
      maxHeight,
      selectedIds,
      isVisible,
      hasBackButton,
      containsFocus,
      onSelectItem,
      onBack,
      onBeforeContentChange,
   }: DropDown2PaneParams<T>) {
      this.pane = unwrap(pane);
      this.selectedIds = selectedIds;
      this.gridSelectedIds = observableArray(Array.from(this.selectedIds()));
      this.cellFactory = cellFactory;
      this.maxHeight = maxHeight;
      this.hasBackButton = hasBackButton;
      this.containsFocus = containsFocus;
      this.onSelectItem = onSelectItem;
      this.onBack = onBack;
      this.onBeforeContentChange = onBeforeContentChange;
      this.subscriptions.push(
         this.pane.gridStore.subscribe(this.onGridStoreChanged, this),
         this.selectedIds.subscribe(this.onSelectedIdsChanged, this),
         this.delayedSearch.subscribe(this.onDelayedSearchChanged, this),
         isVisible.subscribe(this.onIsVisibleChanged, this),
      );

      // Load starting from the selected ID when a single ID is selected.
      if (this.selectedIds().size == 1) {
         this.gridResizeTrigger(Date.now());
         this.pane.loadItems({
            search: null,
            startingAt: this.selectedIds().values().next().value,
         });
      }
   }

   onHeaderKeyDown = (self: this, event: KeyboardEvent): boolean => {
      const isHandled = new EventHandler([
         {
            visit: () =>
               ["ArrowDown", "ArrowUp"].includes(event.key) && this.pane.gridStore() != null,
            accept: () => {
               const lastIndex = this.pane.gridStore()!.rows().length - 1;
               const index = this.findFirstSelectedIndex();
               const rowIndex =
                  event.key == "ArrowDown"
                     ? Math.min(index + 1, lastIndex)
                     : Math.max(index - 1, 0);
               this.cellFocus({ rowIndex, columnIndex: 0 });
            },
         },
         {
            visit: () => event.key == "Escape" && this.hasBackButton(),
            accept: () => this.onBack(),
         },
      ]).handle(event);
      return !isHandled;
   };

   onGridKeyDown = (self: this, event: KeyboardEvent): boolean => {
      const isSearchable = unwrap(this.pane.isSearchable || false);
      const isHandled = new EventHandler([
         {
            visit: () => isSearchable && /^[A-Za-z0-9]$/.test(event.key),
            accept: () => {
               this.search((this.search() || "") + event.key);
               this.cellFocus(null);
               this.isSearchFocused(true);
            },
         },
         {
            visit: () => event.key == "Escape" && this.hasBackButton(),
            accept: () => this.onBack(),
         },
      ]).handle(event);
      return !isHandled;
   };

   onIsVisibleChanged = (isVisible: boolean): void => {
      if (isVisible) {
         this.gridResizeTrigger(Date.now());

         // Load the grid store if it hasn't been loaded yet.
         if (!this.pane.gridStore()) {
            this.pane.loadItems({
               search: null,
               startingAt:
                  this.selectedIds().size == 1 ? this.selectedIds().values().next().value : null,
            });
         }

         // Reset the search when the drop down pane is reshown.
         if (this.search()) {
            this.search(null);
            this.pane.loadItems({
               search: null,
               startingAt:
                  this.selectedIds().size == 1 ? this.selectedIds().values().next().value : null,
            });
         }

         if (!this.hasBeenVisible && this.selectedIds().size == 1) {
            const id: string = this.selectedIds().values().next().value;
            if (
               this.pane
                  .gridStore()
                  ?.rows()
                  .some((r) => r.id == id)
            ) {
               this.firstVisibleRowId(id);
            } else {
               this.waitingToScrollToSelectedRow = true;
            }
         }
         this.hasBeenVisible = true;
      }
   };

   onGridAction = (action: GridAction<T>): void => {
      if (action.type == GridActionType.CELL_CLICK || action.type == GridActionType.CELL_ENTER) {
         this.onSelectItem(action.cell.row.value);
      }
   };

   onGridStoreChanged = (): void => {
      // Queue to guarantee this happens after the grid store is updated.
      queueMicrotask(() => {
         this.gridSelectedIds(Array.from(this.selectedIds()));

         // Scroll to the selected row.
         if (this.waitingToScrollToSelectedRow) {
            this.firstVisibleRowId(this.selectedIds().values().next().value);
            this.waitingToScrollToSelectedRow = false;
         }
      });
   };

   onBeforeGridContentChange = (): void => {
      this.onBeforeContentChange();
   };

   dispose = (): void => {
      this.subscriptions.forEach((s) => s.dispose());
   };

   private onSelectedIdsChanged(selectedIds: Set<string>) {
      if (!isEqualSets(selectedIds, new Set(this.gridSelectedIds()))) {
         this.gridSelectedIds(Array.from(selectedIds));
      }
   }

   private onDelayedSearchChanged(search: string | null) {
      this.pane.loadItems({ search, startingAt: null });
   }

   private findFirstSelectedIndex() {
      // Only return an index when a single item is selected.
      if (!this.pane.gridStore() || this.selectedIds().size != 1) return -1;
      const [id] = this.selectedIds();
      return this.pane
         .gridStore()!
         .rows()
         .findIndex((r) => r.id == id);
   }

   static create<T extends ItemBase>(
      params: DropDown2PaneParams<T>,
   ): ComponentArgs<DropDown2PaneParams<T>> {
      return {
         name: "drop-down-2-pane",
         params,
      };
   }
}

ko.components.register("drop-down-2-pane", {
   viewModel: DropDown2Pane,
   template: template(),
   synchronous: true,
});
