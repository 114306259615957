import { ObservableData } from "@/lib/utils/observable-data"
import { ViewModel } from "@/lib/vm/viewmodel"

export class ModalPane extends ViewModel
   constructor: (title, actionText, template) ->
      assertArgs(arguments, String, optional(String), String)
      super(template)
      @disableHeader = ko.observable(false)
      @title = ko.observable(title)
      @negationText = ko.observable("Close")
      @actionText = if actionText? then ko.observable(actionText) else ko.observable(null)
      @isActionEnabled = ko.observable(true)
      @isEditing = ko.observable(false)
      @modal = null
      @data = null
      @paneClasses = ko.observable()
      @displayingNotice = ko.observable(null)
      @backBtnText = ko.observable("Back")
      @backwardEnabled = ko.observable(true)
      @nextBtnText = ko.observable("Next")
      @nextBtnDisabledText = ko.observable(null)
      @forwardEnabled = ko.observable(true)
      @overflowable = ko.observable(false)

   initialize: (modal, data) ->
      @modal = modal
      @data = data
      @afterInitialize() if @afterInitialize?

   ### Called when this pane has been transitioned to. Direction = (forward/back) ###
   transitionedTo: (direction) ->
      assertArgs(arguments, String)

   ### Called when this pane has been transitioned from. Direction = (forward/back). ###
   transitionedFrom: (direction) ->
      assertArgs(arguments, String)

   setTitle: (title) ->
      assertArgs(arguments, String)
      @title(title)

   setActionText: (actionText) ->
      assertArgs(arguments, String)
      @actionText(actionText)

