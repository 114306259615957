### Base ###
import "./save-view-pane.styl"
import template from "./save-view-pane.pug"
import { Format as FormatUtils } from "@/lib/utils/format"
import ko from "knockout";
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput

### Auth, Real-Time & Stores ###
import { GroupStore } from "@/stores/group-store.core"
import { SavedViewStore } from "@/stores/saved-view-store.core"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"
import { SaveViewPane2 } from "@/lib/components/modals/save-view-pane/save-view-2";

### Models ###
import { Group } from "@/models/group"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { ValueSet } from "@/models/value-set";

export class SaveViewPane extends ModalPane
   constructor: (pageName, viewOptions, editingView) ->
      assertArgs(arguments, nullable(String), nullable(Object), optional(Object))
      # TODO: I don't love that I am passing in viewOptions flat here vs the nested structure.
      super("Save View", "Save", template())
      if editingView?
         @title("Editing Saved View")
      @overflowable(true)
      @isActionEnabled = ko.pureComputed =>
         return true
      
      @pageName = if editingView? then editingView.page else pageName
      @viewOptions = viewOptions
      @editingView = editingView
      @isEditing = @editingView?

      @loadingShareOptions = ko.observable(true)
      @limitedGroups = ko.observableArray()
      @limitedGroupsText = ko.pureComputed =>
         return "" unless @limitedGroups().length > 0
         text = "Sharing limited following groups: "
         # TODO: Cap this around 20 groups. 
         groupNames = ""
         for group in @limitedGroups()
            groupNames += "#{group.name()}, "
         text += groupNames.slice(0, -2)
         return text

      @viewName = ko.observable()
      @shareTypeOptions = [
         new SegmentedControllerItem("Private", "private")
         new SegmentedControllerItem("Individuals", "people")
         new SegmentedControllerItem("Groups", "groups")
      ]
      @selectedShareType = ko.observable(@shareTypeOptions[0])
      
      @selectedPeopleIdsForSharing = ko.observable(new Set())

      @shareWithGroupOptions = ko.observableArray()
      @shareWithGroupSelections = ko.observableArray()

      @saveViewPane = new SaveViewPane2()
      @personDropDownParams = null

      if @viewOptions?
         @evalGroupSharingLimits(@viewOptions.filters)
      
      if @editingView?
         @editSavedView()

   editSavedView: () =>
      if @editingView.group_ids?.length > 0
         limitedGroupIds = @editingView.group_ids
      else
         limitedGroupIds = null
      
      @viewName(@editingView.name)
      for option in @shareTypeOptions
         if option.value() == @editingView.share_type
            @selectedShareType(option)
            break

      @loadPeopleOptions(limitedGroupIds)
      @loadSupportData()


   evalGroupSharingLimits: (filters) =>
      foundGroupBasedFilters = false
      groupLookupData = {
         people_ids: []
         project_ids: []
         position_ids: []
         tag_ids: []
      }

      for filter in filters
         if filter.property == "person_position" || filter.property == "position_id" 
            foundGroupBasedFilters = true
            groupLookupData.position_ids.push(filter.value)
         else if filter.property == "tag_instances" || filter.property == "tag_ids"
            foundGroupBasedFilters = true
            # Can be multiple tags
            tagIds = filter.value.map (valueSet) -> return valueSet.value
            groupLookupData.tag_ids = groupLookupData.tag_ids.concat(tagIds)
         else if filter.property == "person_id" 
            foundGroupBasedFilters = true
            groupLookupData.people_ids.push(filter.value)
         else if filter.property == "project_id"
            foundGroupBasedFilters = true
            groupLookupData.project_ids.push(filter.value)
         else if filter.property == "project_roles"
            foundGroupBasedFilters = true
            groupLookupData.position_ids.push(filter.classifier)
            groupLookupData.people_ids.push(filter.value)
      
      unless foundGroupBasedFilters
         @loadSupportData()
         return @loadPeopleOptions(null)

      # TOOD: Wrap in try catches
      groups = await GroupStore.getEntitiesMutalGroups(groupLookupData).payload

      # TODO: Need to handle situation where no sharable groups are possible. 
      # This shouldn't be an expected state but it could happen.
      @limitedGroups groups.data.map (item) ->
         return new Group(item)
      @shareWithGroupOptions groups.data.map (item) ->
         return new MultiDropDownItem(item.name, item.id, false)
      @loadPeopleOptions groups.data.map (item) -> return item.id

   loadPeopleOptions: (limitedGroupIds) =>
      if @editingView?.shared_with_people_ids?
         @selectedPeopleIdsForSharing(new Set(@editingView.shared_with_people_ids))
      try 
         @personDropDownParams = @saveViewPane.loadPeopleOptions({
               limitedGroupIds,
               selectedPeopleIds: ko.pureComputed(() => @selectedPeopleIdsForSharing()),
               onChange: (personIds) => @selectedPeopleIdsForSharing(personIds)
            })
       catch err
         console.log("Error:", err)

      @loadingShareOptions(false)

   loadSupportData: =>
      groupOptions = []
      stream = await GroupStore.findGroupsStream({}).stream
      for await row from stream
         groupOptions.push(new ValueSet({
            name: row.name,
            value: row.id
         }))

      @shareWithGroupOptions groupOptions.map (valueSet) =>
         if @editingView?.group_ids?
            selected = @editingView.group_ids.indexOf(valueSet.value()) != -1
            if selected
               @limitedGroups.push(new Group(name: valueSet.name(), id: valueSet.value(), true))
         else
            selected = false
         newItem = new MultiDropDownItem(valueSet.name(), valueSet.value(), selected)
         if selected
            @shareWithGroupSelections.push(newItem)
         return newItem
   
   deleteView: =>
      try
         await SavedViewStore.deleteSavedView(@editingView.id).payload
         @data.set('deletedViewId', @editingView.id)
         modalManager.modalFinished()
      catch
         # TODO: display error.
         modalManager.modalFinished()
   
   getSavedViewBaseData: =>
      if @selectedShareType().value() == "people"
         sharedWithPeopleIds = [...@selectedPeopleIdsForSharing()]
      else
         sharedWithPeopleIds = []
      
      if @selectedShareType().value() == "groups"
         groupIds = @shareWithGroupSelections().map((i) -> return i.value())
      else
         groupIds = @limitedGroups().map((i) -> i.id)

      return {
         name: @viewName()
         share_type: @selectedShareType().value()
         shared_with_people_ids: sharedWithPeopleIds
         group_ids: groupIds
      }
   
   saveEditedSavedView: =>
      viewBase = @getSavedViewBaseData()
      try
         await SavedViewStore.updateSavedView(@editingView.id, viewBase).payload
         modalManager.modalFinished()
      catch
         # TODO: display error.
         modalManager.modalFinished()

   createNewSavedView: =>
      viewBase = @getSavedViewBaseData()
      if @viewOptions.columnHeaders?
         columnHeaders = @viewOptions.columnHeaders.map (columnHeader) ->
            width = ko.unwrap(columnHeader.width)
            header = {
               key: columnHeader.key()
               sequence: columnHeader.sequence()
               sortable: columnHeader.sortable()
               width: if width? then Math.floor(width) else null
               sub_properties: columnHeader.subProperties().map (item) ->                  
                  return {
                     ...item,
                     enabled: ko.unwrap(item.enabled) 
                     width: ko.unwrap(item.width) or null
                  }
               meta: columnHeader.meta() or null
            }
            if columnHeader.baseName()? or columnHeader.name()?
               header['name'] = columnHeader.baseName() ? columnHeader.name()

            header['key_id'] = columnHeader.keyId() if columnHeader.keyId()?
            return header
      else
         columnHeaders = []
      
      if @viewOptions.filters?
         chipFilters = @viewOptions.filters.map (item) ->
            return FormatUtils.camelCaseObjectToSnakeCase(item)
      else
         chipFilters = []

      newView = Object.assign(viewBase, {
         page: @pageName
         search: @viewOptions.search or null
         chip_filters: chipFilters
         view_config: {
            start_day: @viewOptions.startDay or null
            viewing_entity: @viewOptions.viewingEntity or null
            viewing_range: @viewOptions.viewingRange or null
            sort_by: @viewOptions.sortBy or null
            sort_direction: @viewOptions.sortDirection or null
            column_headers: columnHeaders
         }
         page_specific: @viewOptions.pageSpecific or null
      })

      try
         await SavedViewStore.createSavedView(newView).payload
         modalManager.modalFinished()
      catch
         # TODO: display error.
         modalManager.modalFinished()


   actionBtnIntercept: =>
      @displayingNotice(SaveViewPane.Notice.SAVING)

      unless validateInput(@viewName())
         @saveClicked = false
         return @displayingNotice(SaveViewPane.Notice.NAME)
      
      if @isEditing
         @saveEditedSavedView()
      else
         @createNewSavedView()
      

SaveViewPane.Notice = {
   SAVING: {
      text: 'Saving your view...'
      info: null
      color: 'green'
      dissmissable: false
   }
   NAME: {
      text: 'Your views must have a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
}
