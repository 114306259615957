import ko from "knockout"
import { ViewModel } from "@/lib/vm/viewmodel"

export class PopupPane extends ViewModel
   constructor: (template) ->
      super(template)
      @title = ko.observable()
      @leftActionBtn = ko.observable()
      @rightActionBtn = ko.observable()
      @dismissHandler = null

   initialize: (dismissHandler) =>
      @dismissHandler = dismissHandler

   ### Called when this pane has been transitioned to. Direction = (forward/back) ###
   ### eslint-disable-next-line no-unused-vars ###
   transitionedTo: (direction) ->
      assertArgs(arguments, String)

   ### Called when this pane has been transitioned from. Direction = (forward/back). ###
   ### eslint-disable-next-line no-unused-vars ###
   transitionedFrom: (direction) ->
      assertArgs(arguments, String)

