export class PageableLoadingMediator
   constructor: (initCallback) ->
      @initCallback = initCallback
      @isInitialized = ko.observable(false)

   showLoader: -> throw new Error "showLoader not set"
   hideLoader: -> throw new Error "hideLoader not set"
   startProgress: -> throw new Error "startProgress not set"
   appendData: (next) -> throw new Error "appendData not set"
   prependData: (next) -> throw new Error "prependData not set"

   initialize: (component) =>
      @showLoader = component.showLoader if component.showLoader?
      @hideLoader = component.hideLoader if component.hideLoader?
      @startProgress = component.startProgress if component.startProgress?
      @appendData = component.appendData if component.appendData?
      @prependData = component.prependData if component.prependData?
      @isInitialized(true)
      @initCallback() if @initCallback?

