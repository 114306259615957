export class CacheManager {
   private readonly cache = new Map<unknown, unknown>();

   add(key: unknown, item: unknown): this {
      this.cache.set(key, item);
      return this;
   }

   remove(key: unknown): this {
      this.cache.delete(key);
      return this;
   }

   get(key: unknown, defaultValue: unknown = null): unknown | null {
      return this.cache.has(key) ? this.cache.get(key) : defaultValue;
   }
}

export const cacheManager = new CacheManager();
