import type {
   StoreJsonResponse,
   StoreRequestErrorHandlingOptions,
} from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type {
   CreateSavedViewPayload,
   CreateSavedViewResponse,
} from "@laborchart-modules/lc-core-api/dist/api/saved-views/create-saved-view";
import type { GetSavedViewResponse } from "@laborchart-modules/lc-core-api/dist/api/saved-views/get-saved-view";
import type { FindUsersSavedViewsResponse } from "@laborchart-modules/lc-core-api/dist/api/saved-views/find-users-saved-views";
import type {
   UpdateSavedViewResponse,
   UpdateSavedViewPayload,
} from "@laborchart-modules/lc-core-api/dist/api/saved-views/update-saved-view";
import type { DeleteSavedViewResponse } from "@laborchart-modules/lc-core-api/dist/api/saved-views/delete-saved-view";

export abstract class SavedViewStore extends Store {
   static createSavedView(
      request: CreateSavedViewPayload,
   ): StoreJsonResponse<CreateSavedViewResponse> {
      return this.requestJson({
         url: "/api/v3/saved-views",
         method: "POST",
         body: request,
      });
   }

   static getSavedView(savedViewId: string): StoreJsonResponse<GetSavedViewResponse> {
      return this.requestJson({
         url: `/api/v3/saved-views/${savedViewId}`,
         method: "GET",
      });
   }

   static getUsersSavedViews(): StoreJsonResponse<FindUsersSavedViewsResponse> {
      return this.requestJson({
         url: "/api/v3/users-saved-views",
         method: "GET",
      });
   }

   static updateSavedView(
      savedViewId: string,
      update: UpdateSavedViewPayload,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreJsonResponse<UpdateSavedViewResponse> {
      return this.requestJson({
         url: `/api/v3/saved-views/${savedViewId}`,
         method: "PATCH",
         body: update,
         ...errorHandlingOptions,
      });
   }

   static deleteSavedView(
      savedViewId: string,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreJsonResponse<DeleteSavedViewResponse> {
      return this.requestJson({
         url: `/api/v3/saved-views/${savedViewId}`,
         method: "DELETE",
         ...errorHandlingOptions,
      });
   }
}
