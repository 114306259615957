import "./support-list-pane.styl";
import template from "./support-list-pane.pug";
import { PopupPane } from "@/lib/components/popup/popup-pane";
import {
   initializeLiveChat,
   isLiveChatOnline,
   LIVEAGENT_OFFLINE_ID,
   LIVEAGENT_ONLINE_ID,
   setIsOnlineCallback,
   startLiveSupportChat,
} from "@/vendor/salesforce-live-chat";
import type { Observable } from "knockout";
import { observable, pureComputed } from "knockout";

export enum LiveChatState {
   LOADING = "loading",
   ONLINE = "online",
   OFFLINE = "offline",
}

export class SupportListPane extends PopupPane {
   private readonly liveChatState: Observable<LiveChatState>;

   readonly liveAgentOnlineId = LIVEAGENT_ONLINE_ID;
   readonly liveAgentOfflineId = LIVEAGENT_OFFLINE_ID;

   readonly isLoading = pureComputed(() => this.liveChatState() == LiveChatState.LOADING);
   readonly displayOnlineOption = pureComputed(() =>
      this.liveChatState() == LiveChatState.ONLINE ? { display: "block" } : { display: "none" },
   );
   readonly displayOfflineOption = pureComputed(() =>
      this.liveChatState() == LiveChatState.OFFLINE ? { display: "block" } : { display: "none" },
   );

   constructor() {
      super(template());

      this.liveChatState = observable<LiveChatState>(
         isLiveChatOnline == null
            ? LiveChatState.LOADING
            : isLiveChatOnline == true
            ? LiveChatState.ONLINE
            : LiveChatState.OFFLINE,
      );
      setIsOnlineCallback((isOnline: boolean) => {
         this.liveChatState(isOnline ? LiveChatState.ONLINE : LiveChatState.OFFLINE);
      });
      initializeLiveChat().catch(() => this.liveChatState(LiveChatState.OFFLINE));
   }

   showLiveChat(): void {
      startLiveSupportChat();
   }
}
