import type { ComponentArgs } from "../common";
import type {
   EditorComponentFactory,
   EditorComponentParams,
} from "../editors/common/editor-component";
import type { GridCell, GridCellContext } from "./grid-cell";
import type { RowBase } from "./grid-store";

export type GridColumnHeader<THeaderParams = unknown> =
   | string
   | (ComponentArgs<THeaderParams> & { textName?: string });

export type GridCellFactory<Row, TCellParams = unknown> = (
   data: Row,
   context: GridCellContext,
) => GridCell<TCellParams>;

export enum GridCursorState {
   /** Cursor is non-actionable. Prevents the editor from being shown. */
   NON_ACTIONABLE = "non_actionable",

   /** Cursor is shown as actionable. Allows the editor to be shown. */
   ACTIONABLE = "actionable",

   /** Cursor is shown as non-actionable; however the editor can be shown. */
   ACTIONABLE_DISABLED = "actionable_disabled",
}

export enum GridActionSource {
   ENTER_KEY = "enter_key",
   FOCUSED_CLICK = "focused_click",
   UNFOCUSED_CLICK = "unfocused_click",
   DOUBLE_CLICK = "double_click",
}

export enum GridActionResult {
   REMAIN_FOCUSED = "remain_focused",
   SHOW_EDITOR = "show_editor",
}

export type GridActionProvider<T> = (params: GridActionProviderParams<T>) => GridActionResult;
export type GridActionProviderParams<T> = {
   row: T;
   source: GridActionSource;
   cursorState: GridCursorState;
};

/** Grid specific implementation of `EditorComponentFactory`. */
export type GridEditorFactory<
   TSchema,
   TValue = any,
   TSaveValue = TValue,
   TParams extends EditorComponentParams<TValue, TSaveValue> = EditorComponentParams<
      TValue,
      TSaveValue
   >,
> = {
   (params: GridEditorFactoryParams<TSchema>): ComponentArgs<TParams> | null;
};
export type GridEditorFactoryParams<TRow> = {
   row: TRow;
   cursorState: GridCursorState;
};

export type GridColumn<
   TRow extends RowBase,
   THeaderParams = unknown,
   TCellParams = unknown,
   TValue = unknown,
   TSaveValue = TValue,
   TEditorParams extends EditorComponentParams<TValue, TSaveValue> = EditorComponentParams<
      TValue,
      TSaveValue
   >,
> = {
   // TODO: Change type when each entity has its own Column Key enum type.
   key: string;
   header: GridColumnHeader<THeaderParams>;
   width: number;

   /** Factory for rendering the cell component. */
   cellFactory: GridCellFactory<TRow, TCellParams>;

   /**
    * Specifies the cursor state for the cell. Defaults to `GridCursorState.ACTIONABLE` when
    * `editorFactory` is provided and `GridCursorState.NON_ACTIONABLE` otherwise.
    */
   cursorStateProvider?: (row: TRow) => GridCursorState;

   /**
    * Handler for when the action is invoked on a cell.
    * If not specified, the action will default to opening the editor if available;
    * otherwise it will do nothing.
    */
   actionProvider?: GridActionProvider<TRow>;

   /** Factory for rendering the editor component for the cell. Used via the context menu. */
   editorFactory?: GridEditorFactory<TRow, TValue, TSaveValue, TEditorParams>;

   /** Provides the copy content for the cell. Used via the context menu. */
   copyProvider?: (row: TRow) => string;

   /** Provides the navigation URL for the cell. Used via the context menu. */
   urlProvider?: (row: TRow) => string | null;

   /** Whether this column is sortable. */
   isSortable?: boolean;

   /**
    * Whether this column can be auto-expanded when the grid does not fill the horizontal space.
    */
   autoResizable?: boolean;

   /** Whether the width of the column can be resized manually. */
   isResizable?: boolean;

   /** The minimum width a column can be resized to. */
   minWidth?: number;
};

/** Convenience utility for the common case of turning a boolean into a `GridCursorState`. */
export function isActionableCursor(isActionable: boolean): GridCursorState {
   return isActionable ? GridCursorState.ACTIONABLE : GridCursorState.NON_ACTIONABLE;
}

/**
 * Convenience utility for the common case of turning `GridEditorFactory` into a
 * `EditorComponentFactory`.
 */
export function toEditorComponentFactory<
   TSchema,
   TValue,
   TSaveValue = TValue,
   TParams extends EditorComponentParams<TValue, TSaveValue> = EditorComponentParams<
      TValue,
      TSaveValue
   >,
>(
   factory: GridEditorFactory<TSchema, TValue, TSaveValue, TParams>,
   cursorState: GridCursorState = GridCursorState.ACTIONABLE,
): EditorComponentFactory<TSchema, TValue, TSaveValue, TParams> {
   return (rows: TSchema[]) => {
      const [first] = rows;
      return factory({ row: first, cursorState });
   };
}

/**
 * Convenience utility for the common case of turning `EditorComponentFactory` into a
 * `GridEditorFactory`.
 */
export function toGridEditorFactory<
   TSchema,
   TValue,
   TSaveValue = TValue,
   TParams extends EditorComponentParams<TValue, TSaveValue> = EditorComponentParams<
      TValue,
      TSaveValue
   >,
>(
   factory: EditorComponentFactory<TSchema, TValue, TSaveValue, TParams>,
): GridEditorFactory<TSchema, TValue, TSaveValue, TParams> {
   return ({ row }: GridEditorFactoryParams<TSchema>) => {
      return factory([row]);
   };
}
