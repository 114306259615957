import { DateUtils } from "@/lib/utils/date"
import { Format } from "@/lib/utils/format"

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"

# TODO: This needs to be a shared resource across services
NotificationContentFormatter = {}
NotificationContentFormatter.FormatSubject = (notification) ->
   assertArgs(arguments, Object)
   category = notification.category().replace(/-/g, "_")
   for key, formatter of NotificationContentFormatter.Subject
      if category == key
         return formatter(notification)
   return null

NotificationContentFormatter.FormatContent = (notification) ->
   assertArgs(arguments, Object)
   category = notification.category().replace(/-/g, "_")
   for key, formatter of NotificationContentFormatter.Content
      if category == key
         return formatter(notification)
   return null

NotificationContentFormatter.Subject = {
   person_existence_changed: (data) ->
      return if data.action() == "create" then "Person Created" else "Person Deleted"
   person_groups_changed: () ->
      return "Person Group Change"
   person_status_changed: () ->
      return "Person Status Change"
   person_tag_warning: () ->
      return "Person Tag Warning"
   person_tag_expired: () ->
      return "Person Tag Expired"
   project_existence_changed: (data) ->
      return if data.action() == "create" then "Project Created" else "Project Deleted"
   project_groups_changed: () ->
      return "Project Group Change"
   project_status_changed: () ->
      return "Project Status Change"
   assigned_as_project_role: () ->
      return "Selected as Project Role"
   assignment_created: () ->
      return "New Assignment"
   assignment_edited: () ->
      return "Assignment Edited"
   assignment_deleted: () ->
      return "Assignment Canceled"
   assignment_starting: () ->
      return "Assignment(s) Starting Soon"
   assignment_ending: () ->
      return "Assignment(s) Ending Soon"
   request_created: () ->
      return "New Request"
   request_edited: () ->
      return "Request Edited"
   request_deleted: () ->
      return "Request Canceled"
   request_filled: () ->
      return "Request Filled"
   request_starting: () ->
      return "Request(s) Starting Soon"
   request_ending: () ->
      return "Request(s) Ending Soon"
}

NotificationContentFormatter.Content = {
   # TODO: Handle Admin conversions here so it shows 'all' instead of '0'
   person_existence_changed: (data) ->
      if Array.isArray(data.details().groups)
         groups = data.details().groups
            .filter((item) -> return !item.archived)
            .map((item) -> return item.name)
            .sort()
      else
         groups = []
      
      groupNames = groups.slice(0, 5).join(', ')
      if groups.length > 5
         groupNames += " and #{groups.length - 1} more"

      maybePluralGroup = if groups.length > 1 then "Groups" else "Group"

      action = if data.action() == "create" then "was created in" else "was deleted from"
      return "#{data.subject1Name().first} #{data.subject1Name().last} #{action} the #{groupNames} #{maybePluralGroup}"

   person_groups_changed: (data) -> 
      groups = data.details().groups
      .filter (item) -> return !item.archived
      .map (item) -> return item.name
      groups.sort()
      
      groupNames = groups.slice(0, 5).join(', ')
      if groups.length > 5
         groupNames += " and #{groups.length - 1} more"

      return "#{data.subject1Name().first} #{data.subject1Name().last} now belongs to #{groupNames}"

   person_status_changed: (data) ->
      newStatus = Format.capitalize(data.meta().new_val)
      return "#{data.subject1Name().first} #{data.subject1Name().last} is now #{newStatus}"

   person_tag_warning: (data) ->
      exprDate = DateUtils.formatDetachedDay(data.meta().expr_date, defaultStore.getDateFormat())
      return "The #{data.subject2Name()} tag has just entered it's #{data.details().expr_days_warning} day warning period to expire on #{exprDate} for #{data.subject1Name().first} #{data.subject1Name().last}"

   person_tag_expired: (data) ->
      return "The #{data.subject2Name()} tag has just expired on #{data.subject1Name().first} #{data.subject1Name().last}"

   project_existence_changed: (data) ->
      if data.details().groups
         groups = data.details().groups
            .filter (item) -> return !item.archived
            .map (item) -> return item.name
         groups.sort()
      else
         groups = []
      
      groupNames = groups.slice(0, 5).join(', ')
      if groups.length > 5
         groupNames += " and #{groups.length - 1} more"

      maybePluralGroup = if groups.length > 1 then "Groups" else "Group"

      action = if data.action() == "create" then "was created in" else "was deleted from"
      return "#{data.subject1Name()} #{action} the #{groupNames} #{maybePluralGroup}"

   project_groups_changed: (data) -> 
      groups = data.details().groups
      .filter (item) -> return !item.archived
      .map (item) -> return item.name
      groups.sort()
      
      groupNames = groups.slice(0, 5).join(', ')
      if groups.length > 5
         groupNames += " and #{groups.length - 1} more"

      return "#{data.subject1Name()} now belongs to #{groupNames}"

   project_status_changed: (data) ->
      newStatus = Format.capitalize(data.meta().new_val)
      return "#{data.subject1Name()} is now #{newStatus}"

   assigned_as_project_role: (data) ->
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      return "You've been selected as the #{data.subject2Name()} role on the #{projectName} project"

   assignment_created: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat())
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()

      return "#{data.subject2Name().first} #{data.subject2Name().last} was assigned" +
      " to #{projectName} from #{formattedStartDate} to #{formattedEndDate}"

   assignment_edited: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat())
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()

      return "#{data.subject2Name().first} #{data.subject2Name().last}'s' assignment" +
      " on #{projectName} from #{formattedStartDate} to #{formattedEndDate} was edited"

   assignment_deleted: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = if data.meta().end_day? then DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat()) else "TBD"
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()

      return "#{data.subject2Name().first} #{data.subject2Name().last}'s' assignment" +
      " on #{projectName} from #{formattedStartDate} to #{formattedEndDate} was canceled"

   assignment_starting: (data) ->
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      maybePluralAssignments = if data.meta().assignments.length == 1 then "assignment" else "assignments"

      html = "#{data.meta().assignments.length} #{maybePluralAssignments}" +
      " on #{projectName} will be starting soon.<br><ul class='notification-meta-list'>"
      for item in data.meta().assignments
         formattedStartDate = DateUtils.formatDetachedDay(item.start_day, defaultStore.getDateFormat())
         formattedEndDate = DateUtils.formatDetachedDay(item.end_day, defaultStore.getDateFormat())
         html += "<li class='notification-meta-list__item'>#{item.person_name.first} #{item.person_name.last} - #{formattedStartDate} - #{formattedEndDate}</li>"
      html += "</ul>"
      return html

   assignment_ending: (data) ->
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      maybePluralAssignments = if data.meta().assignments.length == 1 then "assignment" else "assignments"

      html = "#{data.meta().assignments.length} #{maybePluralAssignments}" +
      " on #{projectName} will be ending soon.<br><ul class='notification-meta-list'>"
      for item in data.meta().assignments
         formattedStartDate = DateUtils.formatDetachedDay(item.start_day, defaultStore.getDateFormat())
         formattedEndDate = DateUtils.formatDetachedDay(item.end_day, defaultStore.getDateFormat())
         html += "<li class='notification-meta-list__item'>#{item.person_name.first} #{item.person_name.last} - #{formattedStartDate} - #{formattedEndDate}</li>"
      html += "</ul>"
      return html

   request_created: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat())
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      positionName = if data.details()?.position? then data.details().position.name else null
      requestsCreated = data.meta().requests_created

      return "#{requestsCreated} new #{positionName or ''} #{if requestsCreated > 1 then 'Requests were' else 'Request was'} created" +
      " for the #{projectName} project from #{formattedStartDate} to #{formattedEndDate}"

   request_edited: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat())
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      positionName = if data.details()?.position? then data.details().position.name else null

      return "A #{positionName or ''} Request" +
      " on the #{projectName} project from #{formattedStartDate} to #{formattedEndDate} was edited"

   request_deleted: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat())
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      positionName = if data.details()?.position? then data.details().position.name else null

      return "A #{positionName or ''} Request was created" +
      " on the #{projectName} project from #{formattedStartDate} to #{formattedEndDate} was canceled"

   request_filled: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat())
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      personName = "#{data.subject3Name().first} #{data.subject3Name().last}"
      positionName = if data.details()?.position? then data.details().position.name else null

      return "#{personName} was assigned to fill a #{positionName or ''} Request " +
      " on the #{projectName} project from #{formattedStartDate} to #{formattedEndDate}"

   request_starting: (data) ->
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      maybePluralAssignments = if data.meta().requests.length == 1 then "request" else "requests"

      html = "#{data.meta().requests.length} #{maybePluralAssignments}" +
      " on #{projectName} will be starting soon.<br><ul class='notification-meta-list'>"
      
      for item in data.meta().requests
         formattedStartDate = DateUtils.formatDetachedDay(item.start_day, defaultStore.getDateFormat())
         formattedEndDate = DateUtils.formatDetachedDay(item.end_day, defaultStore.getDateFormat())
         positionName = item.position_name or "Resource Request"
         html += "<li class='notification-meta-list__item'>#{positionName} - #{formattedStartDate} - #{formattedEndDate}</li>"
      html += "</ul>"
      return html

   request_ending: (data) ->
      projectName = if data.baggage()?.job_number? then "#{data.subject1Name()} - #{data.baggage().job_number}" else data.subject1Name()
      maybePluralAssignments = if data.meta().requests.length == 1 then "request" else "requests"

      html = "#{data.meta().requests.length} #{maybePluralAssignments}" +
      " on #{projectName} will be ending soon.<br><ul class='notification-meta-list'>"
      
      for item in data.meta().requests
         formattedStartDate = DateUtils.formatDetachedDay(item.start_day, defaultStore.getDateFormat())
         formattedEndDate = DateUtils.formatDetachedDay(item.end_day, defaultStore.getDateFormat())
         positionName = item.position_name or "Resource Request"
         html += "<li class='notification-meta-list__item'>#{positionName} - #{formattedStartDate} - #{formattedEndDate}</li>"
      html += "</ul>"
      return html
}


export NotificationContentFormatter = NotificationContentFormatter
