import "./checkbox-cell.styl";
import type { GridActionProvider, GridActionProviderParams, GridCellFactory } from "../grid-column";
import { GridActionResult, GridActionSource, GridCursorState } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./checkbox-cell.pug";
import type { MaybeObservable, Observable, PureComputed } from "knockout";
import ko, { isObservable, isWritableObservable, unwrap } from "knockout";
import type { LabeledCheckboxParams } from "@/lib/components/labeled-checkbox/labeled-checkbox";

export type CheckboxCellParams = {
   value: MaybeObservable<boolean | null> | PureComputed<boolean>;
   alignment?: "left" | "center";
   component?: LabeledCheckboxParams["component"] | null;
   dotColor?: string | null;
   isDisabled?: boolean;
   isFocusable?: boolean;
   title?: string | null;
};

export class CheckboxCell {
   readonly value: CheckboxCellParams["value"];
   readonly classes: string;
   readonly component: Exclude<CheckboxCellParams["component"], undefined>;
   readonly dotColor: CheckboxCellParams["dotColor"];
   readonly isDisabled: Exclude<CheckboxCellParams["isDisabled"], undefined>;
   readonly isFocusable: Exclude<CheckboxCellParams["isFocusable"], undefined>;
   readonly title: Exclude<CheckboxCellParams["title"], undefined>;

   constructor({
      value,
      alignment = "center",
      component = null,
      dotColor = null,
      isDisabled = false,
      isFocusable = false,
      title = null,
   }: CheckboxCellParams) {
      this.value = value;
      this.classes = alignment == "left" ? "checkbox-cell__content--left" : "";
      this.component = component;
      this.dotColor = dotColor;
      this.isDisabled = isDisabled;
      this.isFocusable = isFocusable;
      this.title = title;
   }

   onClick = (self: this, event: MouseEvent): void => {
      if (this.isDisabled) {
         event.stopPropagation();
         return;
      }
      if (
         isWritableObservable(this.value) &&
         (event.target == event.currentTarget || !unwrap(this.isFocusable))
      ) {
         this.value(!this.value());
      }
   };

   static factory<T>(
      provider: (record: T) => CheckboxCellParams,
   ): GridCellFactory<T, CheckboxCellParams> {
      return (record: T): GridCell<CheckboxCellParams> => {
         return {
            component: {
               name: "checkbox-cell",
               params: provider(record),
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(provider: (record: T) => CheckboxCellParams): {
      cellFactory: GridCellFactory<T>;
      cursorStateProvider: (row: T) => GridCursorState;
      actionProvider: GridActionProvider<T>;
   } {
      return {
         cellFactory: CheckboxCell.factory(provider),
         cursorStateProvider: (record: T) =>
            isObservable(provider(record).value)
               ? GridCursorState.ACTIONABLE
               : GridCursorState.NON_ACTIONABLE,
         actionProvider: ({ row, source }: GridActionProviderParams<T>) => {
            if (source == GridActionSource.ENTER_KEY) {
               const value = provider(row).value as Observable<boolean>;
               value(!value());
            }
            return GridActionResult.REMAIN_FOCUSED;
         },
      };
   }
}

ko.components.register("checkbox-cell", {
   viewModel: CheckboxCell,
   template: template(),
   synchronous: true,
});
