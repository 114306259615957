import multiDropDownTemplateFn from "./multi-drop-down.pug"
import { ValidationUtils } from "@/lib/utils/validation"
import { Format as FormatUtils } from "@/lib/utils/format"
import ko from "knockout"

export class MultiDropDownItem
   constructor: (name, value, selected, color, baggage) ->
      assertArgs(arguments, [String, Function], anything(), [Boolean, Function], optional(String), optional(Object))

      if name instanceof Function
         assertOfType(name(), String)
         @name = name
      else
         @name = ko.observable(name)
      if value instanceof Function
         @value = value
      else
         @value = ko.observable(value)
      if selected instanceof Function
         @selected = selected
      else
         @selected = ko.observable(selected)

      @color = color

      # TODO: clean this up. Temp work aroudn for additional_searchables in baggage.
      @baggage = ko.observable(baggage or null)

export class MultiDropDown
   constructor: (params) ->
      assertArgs(arguments, Object)

      if params.actionTitle instanceof Function
         @actionTitle = params.actionTitle
      else if typeof params.actionTitle == "string"
         @actionTitle = ko.observable(params.actionTitle)
      else
         @actionTitle = null

      if params.defaultTitle instanceof Function
         @defaultTitle = params.defaultTitle
      else if typeof params.defaultTitle == "string"
         @defaultTitle = ko.observable(params.defaultTitle)
      else
         @defaultTitle = ko.observable("Select an Option")

      if params.selectedValues instanceof Function
         @selectedValues = params.selectedValues
      else
         @selectedValues = ko.observableArray([])

      assertOfType(params.values, [Function, arrayOf(MultiDropDownItem)])
      if params.values instanceof Function
         assertOfType(params.values(), arrayOf(MultiDropDownItem))
         @values = params.values
      else
         @values = ko.observableArray(params.values)

      if params.selectCallback
         assertOfType(params.selectCallback, Function)
         @selectCallback = params.selectCallback

      @searchQuery = ko.observable('')
      @searchPlaceholder = ko.observable(params.searchPlaceholder or 'Type search here')

      if params.searchable
         if params.searchable instanceof Function
            assertOfType(params.searchable(), Boolean)
            @searchable = params.searchable
         else
            @searchable = ko.observable(params.searchable)
      else
         @searchable = false

      if params.clearable
         if params.clearable instanceof Function
            assertOfType(params.clearable(), Boolean)
            @clearable = params.clearable
         else
            @clearable = ko.observable(params.clearable)
      else
         @clearable = null

      @isExpanded = ko.observable(false)
      @currentTitle = ko.pureComputed =>
         selectedCount = @selectedValues().length
         unless @isExpanded()
            return "#{selectedCount} Selected" unless selectedCount == 0
         return @defaultTitle()

      @displayValues = ko.pureComputed =>
         filteredValues = @values()
         if ValidationUtils.validateInput(@searchQuery())
            search = @searchQuery().toLowerCase()
            return filteredValues = filteredValues.filter (item) ->
               if item.baggage?()?.additional_searchables?
                  for additonal in item.baggage().additional_searchables
                     return true if additonal.toLowerCase().indexOf(search) >= 0
               return item.name().toLowerCase().indexOf(search) >= 0
         return filteredValues if params.disableSort
         return FormatUtils.keyableSort(filteredValues, 'name')

      @selectAllEnabled = ko.observable(params.selectAllEnabled or false)
      @selectAllSelected = ko.observable(false)

      @searchQuery.subscribe =>
         @toggleSelectAll() if @selectAllSelected()

   toggleDropDown: =>
      @isExpanded(!@isExpanded())

   toggleSelectAll: ->
      if @selectAllSelected()
         for option in @values()
            option.selected(false)
            @selectCallback(option) if @selectCallback
         @selectAllSelected(false)
         @selectedValues([])
      else
         for option in @selectedValues()
            option.selected(false)
            @selectCallback(option) if @selectCallback
         @selectedValues([])
         for option in @displayValues()
            option.selected(true)
            @selectCallback(option) if @selectCallback
         # Needs to be this way so you can unselect a item after select all without it
         # removing that item form a shared array between selectedValues and values.
         @selectedValues(@displayValues().map (item) -> return item)
         @selectAllSelected(true)

   # This is required as opposed to just passing the 'toggleDropDown' to the dropdownDismisser
   # custom binding because the 'if isExpanded' check needs to be preformed and we can't do
   # that inside a annoymous function in the event listener that will call this becuase it
   # prevents the removal of the event listener if the callback is anonymous.
   maybeDismissDropDown: =>
      if @isExpanded()
         @isExpanded(false)

   valueSelected: (data) =>
      data.selected(!data.selected())
      existingItem = null
      for option in @selectedValues()
         if option.value() == data.value()
            existingItem = option
            break
      if existingItem?
         @selectedValues.remove(existingItem)
      else
         @selectedValues.push(data)

      @selectCallback(data) if @selectCallback

   clearSelection: =>
      return unless @clearable()
      for option in @selectedValues()
         option.selected(false)
      @selectAllSelected(false)
      @selectedValues([])

multiDropDownTemplate = multiDropDownTemplateFn()

ko.components.register("multi-drop-down",
   viewModel: MultiDropDown,
   template: multiDropDownTemplate
)

