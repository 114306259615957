import "./modal-manager-2.styl";
import template from "./modal-manager-2.pug";
import type { Observable } from "knockout";
import ko, { observable, pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { Size } from "@/lib/utils/geometry";
import type { OptionalSize, CanRequestSize } from "@/lib/components/modals/common/sized-modal";

const DEFAULT_MODAL_SIZE: Size = {
   height: 500,
   width: 800,
};

export type ShowModalParams = {
   modal: ComponentArgs<CanRequestSize>;
};

class ModalManagerState {
   private readonly internalState: {
      activeModal: Observable<ComponentArgs<CanRequestSize> | null>;
      modalOpacity: Observable<0 | 1>;
      requestedSize: Observable<OptionalSize>;
   };

   readonly activeModal = pureComputed(() => this.internalState.activeModal());
   readonly modalOpacity = pureComputed(() => this.internalState.modalOpacity());
   readonly requestedSize = pureComputed(() => this.internalState.requestedSize());

   readonly modalHeight = pureComputed(() => {
      return `${this.modalSize().height}px`;
   });
   readonly modalWidth = pureComputed(() => {
      return `${this.modalSize().width}px`;
   });
   readonly modalSize = pureComputed(() => {
      const requestedSize = this.requestedSize() ?? DEFAULT_MODAL_SIZE;
      const height = Math.min(
         requestedSize.height ?? DEFAULT_MODAL_SIZE.height,
         $(window).height() ?? DEFAULT_MODAL_SIZE.height,
      );
      const width = Math.min(
         requestedSize.width ?? DEFAULT_MODAL_SIZE.width,
         $(window).width() ?? DEFAULT_MODAL_SIZE.width,
      );
      return { height, width };
   });

   constructor() {
      this.internalState = {
         activeModal: observable(null),
         modalOpacity: observable<0 | 1>(0),
         requestedSize: observable<OptionalSize>(DEFAULT_MODAL_SIZE),
      };
   }

   clearActiveModal = () => {
      this.internalState.modalOpacity(0);
      setTimeout(() => {
         this.internalState.activeModal(null);
      }, 300);
   };

   setActiveModal = ({ modal }: { modal: ComponentArgs<CanRequestSize> }) => {
      this.internalState.activeModal(modal);
      setTimeout(() => {
         this.internalState.modalOpacity(1);
      }, 10);
   };

   updateRequestedSize = (size: OptionalSize) => {
      this.internalState.requestedSize(size);
   };
}

export class ModalManager {
   private readonly state: ModalManagerState;

   constructor() {
      this.state = new ModalManagerState();
   }

   clearModal = (): void => {
      this.state.clearActiveModal();
   };

   requestSize = (size: OptionalSize): void => {
      this.state.updateRequestedSize(size);
   };

   setModal = ({ modal }: { modal: ComponentArgs<CanRequestSize> }): void => {
      this.state.setActiveModal({ modal });
   };
}

ko.components.register("modal-manager-2", {
   viewModel: ModalManager,
   template: template(),
   synchronous: true,
});

export const modalManager = new ModalManager();
