import "./user-toolbar-pane.styl"
import template from "./user-toolbar-pane.pug"
import { router } from "@/lib/router"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { AuthManager2 } from "@/lib/managers/auth-manager-2"
import { groupStore } from "@/stores/group-store"
import { GroupStore } from "@/stores/group-store"

### Popups ###
import { PopupPane } from "@/lib/components/popup/popup-pane"

export class UserToolbarPane extends PopupPane
   constructor: () ->
      super(template())



   navigateToSettings: ->
      router.navigate(null, "/company/#{authManager.companyId()}/settings")
      @dismissHandler()

   signout: ->
      executeSignout = =>
         groupStore.emptyVault(GroupStore.ValutKey.GROUP_OPTIONS)
         authManager.logout () ->
            AuthManager2.navigateToSignIn()

      curChild = @getChild("main")
      if curChild?.dispose?
         curChild.dispose(executeSignout)
      else
         executeSignout()