import type {
   Observable,
   MaybeObservable,
   Subscription,
   Subscribable,
   MaybeSubscribable,
} from "knockout";
import ko, { observable, pureComputed, isObservable, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import template from "./transitioning-content.pug";
import "./transitioning-content.styl";

export enum Transition {
   NONE = "none",
   FORWARD = "forward",
   BACKWARD = "backward",
   UPWARD = "upward",
   DOWNWARD = "downward",
   FADE = "fade",
}

export type TransitionHandler = (index: number) => void;

export interface TransitioningContentParams {
   activeIndex: Observable<number> | number | null;
   transition?: MaybeObservable<Transition | null>;
   onBeforeVisible?: MaybeObservable<TransitionHandler | null>;
   onBeforeHidden?: MaybeObservable<TransitionHandler | null>;
   onVisible?: MaybeObservable<TransitionHandler | null>;
   onHidden?: MaybeObservable<TransitionHandler | null>;
   components?: MaybeObservable<ComponentArgs[]>;
   data?: MaybeObservable<unknown> | null;
   resizeTrigger?: Subscribable<unknown>;
   ignoreWidth?: MaybeObservable<boolean>;
   ignoreHeight?: MaybeObservable<boolean>;
   onResizeEnd?: () => void;
}

/**
 * @example
 * <transitioning-content data-bind="params: { activeIndex: 1 }">
 *    // Each of the following become a separate pane. See activeIndex.
 *    <div class="1"></div>
 *    <div class="2"></div>
 *    <div class="3"></div>
 * </transitioning-content>
 */
export class TransitioningContent {
   readonly activeIndex: Observable<number | null>;
   readonly components?: MaybeSubscribable<ComponentArgs[]> | null;
   readonly data?: MaybeSubscribable<unknown> | null;
   readonly isAnimating = observable(false);
   readonly activeTransition = pureComputed<Transition>(() => {
      return (this.params.transition && unwrap(this.params.transition)) || Transition.FADE;
   });
   readonly transitionName = pureComputed<string>(() => {
      return `transitioning-content-transition__${this.activeTransition()}`;
   });
   readonly transitionSizeTrigger = observable(0);

   private readonly subscriptions: Subscription[] = [];

   constructor(readonly params: TransitioningContentParams) {
      this.activeIndex = isObservable(params.activeIndex)
         ? params.activeIndex
         : observable(params.activeIndex as number | null);
      this.components = params.components || null;
      this.data = params.data || null;
      this.subscriptions.push(this.activeIndex.subscribe(this.onActiveIndexChanged, this));
      if (this.params.resizeTrigger) {
         this.subscriptions.push(
            this.params.resizeTrigger.subscribe(() => this.transitionSizeTrigger(Date.now())),
         );
      }
   }

   onTransitionStart = (index: number, isVisible: boolean): void => {
      if (isVisible && this.params.onBeforeVisible) {
         this.params.onBeforeVisible(index);
      } else if (!isVisible && this.params.onBeforeHidden) {
         this.params.onBeforeHidden(index);
      }
   };

   onTransitionEnd = (index: number, isVisible: boolean): void => {
      this.isAnimating(false);
      if (isVisible && this.params.onVisible) {
         this.params.onVisible(index);
      } else if (!isVisible && this.params.onHidden) {
         this.params.onHidden(index);
      }
   };

   dispose = (): void => {
      this.subscriptions.forEach((s) => s.dispose());
   };

   private onActiveIndexChanged() {
      this.isAnimating(true);
      this.transitionSizeTrigger(Date.now());
   }
}

ko.components.register("transitioning-content", {
   viewModel: TransitioningContent,
   template: template(),
   synchronous: true,
});
