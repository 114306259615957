export function validateInput(input: string | unknown): input is string {
   if (input == null) return false;
   if (typeof input === "string") {
      return input.replace(/\s/g, "").length > 0;
   }
   return true;
}

export function validateEmail(email: string): boolean {
   /* eslint-disable no-useless-escape */
   const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   /* eslint-enable no-useless-escape */

   return regex.test(email);
}

export function isInt(n: unknown): boolean {
   return (n as number) % 1 === 0;
}

export const ValidationUtils = {
   validateInput,
   validateEmail,
   isInt,
};
