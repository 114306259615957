import "./popup.styl"
import template from "./popup.pug"
import { ObservableData } from "@/lib/utils/observable-data"
import { ViewModel } from "@/lib/vm/viewmodel"

export class Popup extends ViewModel
   constructor: (title, frameType, arrowLocation, panes, ignoreClasses, popupClasses, callback) ->
      # assertArgs(arguments, String, String, String, Array, arrayOf(String), optional(arrayOf(String)), optional(Function))
      super(template())
      @isActive = ko.observable(false)
      @callback = callback
      @dismissHandler = null
      @defaultTitle = ko.observable(title)
      @defaultLeftActionBtn = ko.observable(false)
      @defaultRightActionBtn = ko.observable(false)
      @activePopupBody = ko.observable()
      @ignoreClasses = ko.observableArray()
      @ignoreClasses(ignoreClasses or [])
      @frameType = ko.observable(frameType)
      @frameTypeClass = ko.pureComputed =>
         return "popup-frame--#{@frameType()}" unless @enableOverflow()
         return "popup-frame--#{@frameType()} popup-frame--overflowable"
      @arrowLocationClass = ko.observable("popup-arrow-container--#{arrowLocation}")
      @popupClasses = ko.observable("")
      if popupClasses?
         for name in popupClasses
            @popupClasses("#{@popupClasses()} #{name}")
      @panes = ko.observableArray(panes)
      @activePaneIndex = ko.observable(0)
      @activePane = ko.pureComputed =>
         active = @panes()[@activePaneIndex()]
         active.initialize(@maybeDismissPopup)
         return active
      @title = ko.pureComputed =>
         return if @activePane().title()? then @activePane().title() else @defaultTitle()
      @leftActionBtn = ko.pureComputed =>
         return if @activePane().leftActionBtn()? then @activePane().leftActionBtn() else @defaultLeftActionBtn
      @rightActionBtn = ko.pureComputed =>
         return if @activePane().rightActionBtn()? then @activePane().rightActionBtn() else @defaultRightActionBtn
      @rightActionBtnEnabled = ko.pureComputed =>
         return if @activePane().rightActionBtnEnabled?()? then @activePane().rightActionBtnEnabled() else true

      @enableOverflow = ko.pureComputed =>
         return if @activePane().enableOverflow?()? then @activePane().enableOverflow() else false

   initialize: (dismissHandler) =>
      @dismissHandler = dismissHandler

   leftActionBtnIntercept: ->
      @leftActionBtn.callback()

   # TODO: I don't think this is doing anything. Looks like the markup is hitting the callback.
   rightActionBtnIntercept: ->
      return unless @rightActionBtnEnabled()
      @rightActionBtn.callback()

   pageForward: ->

   pageBackward: ->

   maybeDismissPopup: (status) =>
      status = Popup.ExitStatus.CANCELED unless status?
      if @dismissHandler?
         @dismissHandler()
      @callback(null, status)? if @callback?


Popup.FrameType = {
   ABOVE: "above"
   BELOW: "below"
   LEFT: "left"
   RIGHT: "right"
}

Popup.ArrowLocation = {
   TOP_LEFT: "top-left"
   TOP_RIGHT: "top-right"
   BOTTOM_LEFT: "bottom-left"
   BOTTOM_RIGHT: "bottom-right"
   RIGHT_CENTER: "right-center"
   LEFT_CENTER: "left-center"
}

Popup.ExitStatus = {
   CANCELED: "canceled"
   AFFIRMATIVE_ACTION: "affirmative-action"
   NEGATING_ACTION: "negating-action"
}