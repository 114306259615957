import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { GetTagInstancesResponse } from "@laborchart-modules/lc-core-api/dist/api/tags/get-tag-instances";
import type {
   FindPeoplePaginatedResponse,
   FindPeoplePaginatedQueryParams,
   StreamPersonUpdatesPayload,
   UpdateSinglePersonResponse,
   UpdatePersonPayload,
   FindPeopleStreamQueryParams,
   FindPeopleListPaginatedResponse,
   SerializedPeopleListPerson,
} from "@laborchart-modules/lc-core-api/dist/api/people";
import type {
   GetPersonDetailsResponse,
   GetPersonResponse,
} from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import type { AuthType } from "@laborchart-modules/lc-core-api/dist/api/shared";
import type { StreamedUpdate } from "@laborchart-modules/lc-core-api/dist/api/streams";
import type {
   RefreshAssociatedProjectsPayload,
   RefreshAssociatedProjectsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/people/refresh-associated-projects";
import type { DeletePersonResponse } from "@laborchart-modules/lc-core-api/dist/api/people/delete-person";
import type { SerializedPerson } from "@laborchart-modules/common/dist/rethink/serializers/person-serializer";
import type { BatchDeletePeoplePayload } from "@laborchart-modules/lc-core-api/dist/api/people/delete-person";
import type {
   CreatePersonPayload,
   CreatePersonResponse,
} from "@laborchart-modules/lc-core-api/dist/api/people/create-person";
import type {
   UpdateUserPayload,
   UpdateUserResponse,
} from "@laborchart-modules/lc-core-api/dist/api/people/update-user";
import type { GetPersonQrInfoResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-person-qr-info";
import type { GetPeopleForMessageResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-people-for-message";
import type { GetHomeInfoResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-home-info";
import type { UnlockUserResponse } from "@laborchart-modules/lc-core-api/dist/api/people/unlock-user";
import type { GetAbbrvPersonResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-abbrv-person";
import type { GetUserColumnHeadersResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-user-column-headers";
import type { ResetColumnHeadersResponse } from "@laborchart-modules/lc-core-api/dist/api/people/reset-column-headers";
import type {
   UpdateColumnHeadersPayload,
   UpdateColumnHeadersResponse,
} from "@laborchart-modules/lc-core-api/dist/api/people/update-column-headers";
export abstract class PersonStore extends Store {
   static createPerson(payload: CreatePersonPayload): StoreJsonResponse<CreatePersonResponse> {
      return this.requestJson({
         body: payload,
         method: "POST",
         url: "/api/v3/people",
      });
   }

   static findPeople(
      query: FindPeoplePaginatedQueryParams,
   ): StoreJsonResponse<FindPeoplePaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/people",
         method: "GET",
         query,
      });
   }

   static findPeopleStream(
      query: FindPeopleStreamQueryParams,
   ): StoreStreamResponse<SerializedPerson> {
      return this.requestStream({
         method: "GET",
         query,
         url: "/api/v3/people/stream",
      });
   }

   static findPeopleList(
      query: FindPeoplePaginatedQueryParams,
   ): StoreJsonResponse<FindPeopleListPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/people-list",
         method: "GET",
         query,
      });
   }

   static findPeopleListStream(
      query: FindPeopleStreamQueryParams,
   ): StoreStreamResponse<SerializedPeopleListPerson> {
      return this.requestStream({
         url: "/api/v3/people-list/stream",
         query,
         method: "GET",
      });
   }

   static getPerson(personId: string): StoreJsonResponse<GetPersonResponse> {
      return this.requestJson({ url: `/api/v3/people/${personId}` });
   }

   static getPersonDetails(personId: string): StoreJsonResponse<GetPersonDetailsResponse> {
      return this.requestJson({ url: `/api/v3/person-details/${personId}` });
   }

   static getPersonTagInstances(personId: string): StoreJsonResponse<GetTagInstancesResponse> {
      return this.requestJson({ url: `/api/v3/people/${personId}/tag_instances` });
   }

   static refreshAssociatedProjects(
      personId: string,
      update: RefreshAssociatedProjectsPayload,
   ): StoreJsonResponse<RefreshAssociatedProjectsResponse> {
      return this.requestJson({
         url: `/api/v3/people/associated-projects/${personId}`,
         method: "PATCH",
         body: update,
      });
   }

   static updatePeopleStream(
      updates: StreamPersonUpdatesPayload<AuthType.SESSION>,
   ): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/people/stream",
         method: "PATCH",
         body: updates,
      });
   }

   static updatePerson(
      personId: string,
      update: UpdatePersonPayload<AuthType.SESSION>,
   ): StoreJsonResponse<UpdateSinglePersonResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}`,
         method: "PATCH",
         body: update,
      });
   }

   static deletePerson(personId: string): StoreJsonResponse<DeletePersonResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}`,
         method: "DELETE",
      });
   }

   static batchDelete(updates: BatchDeletePeoplePayload): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/people/batch-delete",
         method: "POST",
         body: updates,
      });
   }

   static updateUserSettings(update: UpdateUserPayload): StoreJsonResponse<UpdateUserResponse> {
      return this.requestJson({
         url: `/api/v3/settings/people/user`,
         method: "PATCH",
         body: update,
      });
   }

   static getPersonQrInfo(
      companyQrId: string,
      personQrId: string,
   ): StoreJsonResponse<GetPersonQrInfoResponse> {
      return this.requestJson({
         url: `/api/v3/qr-companies/${companyQrId}/qr-person/${personQrId}`,
         method: "GET",
      });
   }

   static getPeopleForMessage(search?: string): StoreJsonResponse<GetPeopleForMessageResponse> {
      return this.requestJson({
         url: `/api/v3/people/messages`,
         method: "GET",
         query: search ? { search: search } : undefined,
      });
   }

   static getUserHomeInfo(): StoreJsonResponse<GetHomeInfoResponse> {
      return this.requestJson({
         url: `/api/v3/people/home-info`,
         method: "GET",
      });
   }

   static unlockUser(personId: string): StoreJsonResponse<UnlockUserResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/unlock`,
         method: "POST",
      });
   }

   static getColumnHeaders(
      personId: string,
      listViewType: string,
   ): StoreJsonResponse<GetUserColumnHeadersResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/column-headers/${listViewType}`,
         method: "GET",
      });
   }

   static resetColumnHeaders(
      personId: string,
      listViewType: string,
   ): StoreJsonResponse<ResetColumnHeadersResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/column-headers/${listViewType}/reset`,
         method: "DELETE",
      });
   }
   static getPersonAbbrv(personId: string): StoreJsonResponse<GetAbbrvPersonResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/abbrv`,
         method: "GET",
      });
   }

   static updateColumnHeaders(
      personId: string,
      listViewType: string,
      updateBody: UpdateColumnHeadersPayload[],
   ): StoreJsonResponse<UpdateColumnHeadersResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/column-headers/${listViewType}`,
         method: "PATCH",
         body: updateBody,
      });
   }
}
