import "./drop-down-2.styl";
import template from "./drop-down-2.pug";
import type {
   MaybeObservable,
   MaybeSubscribable,
   Observable,
   ObservableArray,
   PureComputed,
   Subscription,
} from "knockout";
import ko, { isObservable, observable, observableArray, pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "../common";
import type { DropDownPane, ItemBase } from "./drop-down-pane";
import type { Bounds, Size } from "@/lib/utils/geometry";
import {
   AnchoredPosition,
   contains,
   findAnchoredBounds,
   findIntersection,
} from "@/lib/utils/geometry";
import type { GridCellFactory } from "../grid/grid-column";
import { EventHandler } from "@/lib/utils/event-handler";
import type { DropDown2PaneParams } from "@/lib/components/drop-downs/drop-down-2-pane";
import { DropDown2Pane } from "@/lib/components/drop-downs/drop-down-2-pane";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import { isEqualSets } from "@/lib/utils/sets";

export enum ActionResult {
   /**
    * Toggles the selection state of the item without changing other selections or closing the
    * dropdown.
    */
   TOGGLE = "toggle",

   /** Selects the item and deselects all other items. Does not close the dropdown. */
   SELECT_ONLY = "select_only",

   /**
    * Toggles the selection state of the item without changing other selections and closes
    * the dropdown.
    */
   TOGGLE_AND_CLOSE = "toggle_and_close",

   /** Selects the item, deselects all other items and close the dropdown. */
   SELECT_ONLY_AND_CLOSE = "select_and_close",

   /** Closes the dropdown without selecting the item. */
   CLOSE = "close",

   /**
    * Ignores the interaction entirely. The selection will not be changed and the
    * dropdown will not be closed.
    */
   IGNORE = "ignore",
}

export type ActionInterceptor<T extends ItemBase> = (item: T) => ActionResult;

export type DropDown2Params<T extends ItemBase> = {
   /**
    * Panes providing the items to display. Pushing a new pane onto this array
    * will trigger the new pane to be displayed.
    * Note: The panes can be updated while the dropdown is visible but the
    * set of updates is somewhat limited. See `onPanesChanged` for restrictions.
    */
   panes: MaybeObservable<Array<DropDownPane<T>>>;

   /** Cell factory used to render each of the dropdown items. */
   cellFactory: GridCellFactory<T>;

   /**
    * Cell factory used to render the selected item in the dropdown.
    * Defaults to using the `cellFactory` when this is undefined.
    */
   selectedItemCellFactory?: GridCellFactory<T>;

   /** Selected item. Will be null when either no items are selected or more than one items are selected. */
   selectedItem?: Observable<T | null> | PureComputed<T | null>;

   /** Set of the selected IDs. */
   selectedIds?: Observable<Set<string>> | PureComputed<Set<string>>;

   /**
    * Action interceptor for customizing the interaction when an item is selected.
    * Defaults to always returning `ActionResult.SELECT_ONLY_AND_CLOSE`.
    */
   actionInterceptor?: ActionInterceptor<T>;

   /** Whether the dropdown is disabled. */
   isDisabled?: MaybeObservable<boolean> | PureComputed<boolean>;

   /** Whether the dropdown can be cleared using the clear button. */
   isClearable?: MaybeObservable<boolean>;

   /** Whether the dropdown direction can be inverted **/
   isInvertible?: boolean;

   /** Placeholder text shown when no option has been selected. */
   placeholder?: MaybeObservable<string | null> | PureComputed<string | null>;

   /** Can be used to override content text. Returning null resumes default behavior. */
   selectionDescriptionProvider?: (selectedIds: Set<string>) => string | ComponentArgs | null;
};

interface PaneComponentArgs<T extends ItemBase> extends ComponentArgs<DropDown2PaneParams<T>> {
   dispose(): void;
}

const DEFAULT_MAX_DROP_DOWN_HEIGHT = 200;
const MINIMUM_MAX_DROP_DOWN_HEIGHT = 112;
const DEFAULT_PLACEHOLDER_TEXT = "Select Option...";

export class DropDown2<T extends ItemBase> {
   readonly paneComponents: ObservableArray<PaneComponentArgs<T>>;
   readonly selectedItem: Observable<T | null> | PureComputed<T | null>;
   readonly selectedIds: Exclude<DropDown2Params<T>["selectedIds"], undefined>;
   readonly isDisabled: MaybeSubscribable<boolean>;
   readonly isInvertible: boolean;

   readonly transition = observable(Transition.FADE);
   readonly isInverted = observable(true);
   readonly maxDropDownHeight = observable(DEFAULT_MAX_DROP_DOWN_HEIGHT);
   readonly isButtonFocused = observable(false);
   readonly contentResizeTrigger = observable(0).extend({ notify: "always" });
   readonly buttonContentSize = observable<Size>({ height: 0, width: 0 });
   readonly viewport = observable<Bounds>({ top: 0, left: 0, width: 0, height: 0 });
   readonly viewportBounds = observable<Bounds>({ top: 0, left: 0, width: 0, height: 0 });
   readonly checkViewportTrigger = observable(0).extend({ notify: "always" });

   readonly activePaneIndex = observable<number | null>(null);
   readonly isExpanded = pureComputed<boolean>({
      read: () => {
         return this.activePaneIndex() != null;
      },
      write: (value: boolean) => {
         if (value) {
            if (this.panes().length) {
               this.transition(Transition.FADE);
               this.activePaneIndex(this.panes().length - 1);
               this.isExpandedWithDelay(true);
               // Overwrite false value if someone de-expands/re-expands in under 300ms.
               setTimeout(() => this.isExpandedWithDelay(true), 300);
            }
         } else {
            this.transition(Transition.FADE);
            this.activePaneIndex(null);
            setTimeout(() => this.isExpandedWithDelay(false), 300);
         }
      },
      owner: this,
   });
   readonly isExpandedWithDelay = observable(unwrap(this.isExpanded));
   readonly buttonContent = pureComputed<string | ComponentArgs>(() => {
      if (this.selectionDescriptionProvider) {
         const content = this.selectionDescriptionProvider(this.selectedIds());
         if (content) return content;
      }
      const selectedItem = this.selectedItem();
      if (selectedItem) {
         const cell = this.selectedItemCellFactory(selectedItem, {
            width: this.buttonContentSize().width,
         });
         return cell.component;
      }
      const selectedIds = this.selectedIds();
      return selectedIds.size > 1
         ? `${this.selectedIds().size} Selected`
         : unwrap(this.placeholder) ?? DEFAULT_PLACEHOLDER_TEXT;
   });

   readonly buttonIcon = pureComputed(() => {
      if (this.activePaneIndex() == null) {
         return unwrap(this.isDisabled) ? "icon-drop-down-expanded-gray" : "icon-drop-down-expand";
      }
      return "icon-drop-down-close";
   });

   readonly hasClearButton = pureComputed(() => {
      return unwrap(this.isClearable) && !unwrap(this.isDisabled) && this.selectedIds().size > 0;
   });

   private readonly activeRows = pureComputed(() => {
      const gridStore = this.panes()[this.activePaneIndex() ?? 0]?.gridStore() || null;
      return gridStore ? gridStore.rows() : [];
   });

   private readonly actionInterceptor: Exclude<DropDown2Params<T>["actionInterceptor"], undefined>;
   private readonly cellFactory: DropDown2Params<T>["cellFactory"];
   private readonly isClearable: Exclude<DropDown2Params<T>["isClearable"], undefined>;
   private readonly panes: Extract<DropDown2Params<T>["panes"], Observable<Array<DropDownPane<T>>>>;
   private readonly placeholder: Exclude<DropDown2Params<T>["placeholder"], undefined>;
   private readonly selectedItemCellFactory: Exclude<
      DropDown2Params<T>["selectedItemCellFactory"],
      undefined
   >;
   private readonly selectionDescriptionProvider: DropDown2Params<T>["selectionDescriptionProvider"];

   private readonly subscriptions: Subscription[] = [];
   private recalculationTimer: any | null;

   constructor({
      panes,
      cellFactory,
      selectedItemCellFactory,
      selectedIds,
      selectedItem,
      actionInterceptor = () => ActionResult.SELECT_ONLY_AND_CLOSE,
      isInvertible = true,
      isDisabled = false,
      isClearable = false,
      placeholder = DEFAULT_PLACEHOLDER_TEXT,
      selectionDescriptionProvider,
   }: DropDown2Params<T>) {
      this.selectedItem = selectedItem || observable(null);
      this.selectedIds =
         selectedIds ||
         observable(this.selectedItem() ? new Set([this.selectedItem()!.id]) : new Set<string>());
      this.panes = isObservable(panes) ? panes : observableArray(panes as any);
      this.actionInterceptor = actionInterceptor;
      this.cellFactory = cellFactory;
      this.selectedItemCellFactory = selectedItemCellFactory
         ? selectedItemCellFactory
         : cellFactory;
      this.isInvertible = isInvertible;
      this.isDisabled = isDisabled;
      this.isClearable = isClearable;
      this.placeholder = placeholder;
      this.selectionDescriptionProvider = selectionDescriptionProvider;
      this.paneComponents = observableArray(this.panes().map((p) => this.createPaneComponent(p)));
      this.subscriptions.push(
         this.panes.subscribe(this.onPanesChanged, this),
         this.activePaneIndex.subscribe(this.onBeforeActivePaneIndexChanged, this, "beforeChange"),
         this.activePaneIndex.subscribe(this.onActivePaneIndexChanged, this),
         this.selectedIds.subscribe(this.onSelectedIdsChanged, this),
         this.selectedItem.subscribe(this.onSelectedItemChanged, this),
         this.activeRows.subscribe(this.onActiveRowsChanged, this),
         this.viewportBounds.subscribe(this.onViewportBoundsChange, this),
      );
   }

   onButtonClick = (): void => {
      if (unwrap(this.isDisabled)) return;
      this.isExpanded(!this.isExpanded());
   };

   onClearClicked = (): void => {
      this.selectedIds(new Set());
      this.isExpanded(false);
   };

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      const isHandled = new EventHandler([
         {
            visit: () => event.key == "Escape" && this.isExpanded(),
            accept: () => {
               this.isExpanded(false);
            },
         },
      ]).handle(event);
      return !isHandled;
   };

   onPaneBeforeVisible = (index: number): void => {
      this.paneComponents()[index].params.isVisible(true);
      this.isButtonFocused(false);
      this.paneComponents()[index].params.containsFocus(true);
   };

   onPaneHidden = (index: number): void => {
      const paneComponents = this.paneComponents();
      paneComponents[index].params.isVisible(false);
      if (!this.isExpanded()) {
         // Clean up all the first panes when the drop down is closed.
         this.panes().splice(1);
      } else if (this.paneComponents().length > this.panes().length) {
         this.paneComponents.splice(this.panes().length);
      }
      this.disposeUnusedPaneComponents(paneComponents);
   };

   dispose = (): void => {
      this.subscriptions.forEach((s) => s.dispose());
      this.paneComponents().forEach((c) => c.dispose());
   };

   static factory<T, Item extends ItemBase>(
      provider: (records: T[]) => DropDown2Params<Item>,
   ): (records: T[]) => ComponentArgs<DropDown2Params<Item>> {
      return (records) => ({
         name: "drop-down-2",
         params: provider(records),
      });
   }

   private onPanesChanged(panes: Array<DropDownPane<T>>): void {
      if (panes.length == 0) {
         this.isExpanded(false);
         return;
      }
      const paneComponents = this.paneComponents();

      const getOrCreateComponent = (pane: DropDownPane<T>) => {
         const reuse = paneComponents.find((component) => component.params.pane == pane);
         return reuse ? reuse : this.createPaneComponent(pane);
      };

      const updatedComponents = panes.map((pane) => getOrCreateComponent(pane));

      if (!this.isExpanded()) {
         this.paneComponents(updatedComponents);
         return;
      }

      const activeIndex = this.activePaneIndex()!;
      const activeComponent = this.paneComponents()[activeIndex];
      const newIndexOfActive = updatedComponents.indexOf(activeComponent);
      const lastIndex = panes.length - 1;

      if (newIndexOfActive == -1) {
         if (lastIndex >= activeIndex) {
            throw new Error("The active pane cannot be replaced.");
         }
         if (activeIndex - 1 > lastIndex) {
            // Unable to insert the active pane at the correct index to
            // transition. Just set the components.
            this.transition(Transition.NONE);
            this.paneComponents(updatedComponents);
            this.activePaneIndex(lastIndex);
         } else {
            // Splice in the updates behind the active component and animate away from the
            // active component. The active pane will be cleaned up after the transition.
            this.paneComponents.splice(0, updatedComponents.length, ...updatedComponents);
            this.transition(Transition.BACKWARD);
            this.activePaneIndex(lastIndex);
         }
         this.disposeUnusedPaneComponents(paneComponents);
         return;
      }

      if (newIndexOfActive != activeIndex) {
         throw new Error("The index of the active pane cannot be changed.");
      }

      this.paneComponents(updatedComponents);
      if (lastIndex != activeIndex) {
         this.transition(Transition.FORWARD);
         this.activePaneIndex(lastIndex);
      }
   }

   private createPaneComponent(pane: DropDownPane<T>): PaneComponentArgs<T> {
      const paneSelectedIds = observable(this.selectedIds());
      const subscription = paneSelectedIds.subscribe(() => {
         this.onPaneSelectedIdsChanged(pane, paneSelectedIds);
      });
      return {
         ...DropDown2Pane.create({
            pane,
            isVisible: observable(false),
            hasBackButton: pureComputed(() => {
               return this.panes().indexOf(pane) > 0;
            }),
            maxHeight: this.maxDropDownHeight,
            cellFactory: this.cellFactory,
            selectedIds: paneSelectedIds,
            onSelectItem: (item) => this.onPaneSelectItem(pane, item),
            onBack: this.onPaneBackClicked,
            containsFocus: observable(false),
            onBeforeContentChange: this.onBeforeGridContentChange,
         }),
         dispose() {
            if (pane.dispose) pane.dispose();
            subscription.dispose();
         },
      };
   }

   private disposeUnusedPaneComponents(paneComponents: Array<PaneComponentArgs<T>>) {
      const currentPaneComponents = this.paneComponents();
      paneComponents.forEach((component) => {
         if (!currentPaneComponents.includes(component)) {
            component.dispose();
         }
      });
   }

   private onPaneSelectItem(pane: DropDownPane<T>, item: T) {
      const result = this.actionInterceptor(item);

      // Only select the selected item.
      if ([ActionResult.SELECT_ONLY, ActionResult.SELECT_ONLY_AND_CLOSE].includes(result)) {
         this.selectedIds(new Set([item.id]));
      }

      // Toggle the selected item.
      if ([ActionResult.TOGGLE, ActionResult.TOGGLE_AND_CLOSE].includes(result)) {
         if (this.selectedIds().has(item.id)) {
            // Deselect the item.
            const newIds = new Set(this.selectedIds());
            newIds.delete(item.id);
            this.selectedIds(newIds);
         } else {
            // Select the item.
            this.selectedIds(new Set(this.selectedIds()).add(item.id));
         }
      }

      // Close the dropdown.
      if (
         [
            ActionResult.CLOSE,
            ActionResult.TOGGLE_AND_CLOSE,
            ActionResult.SELECT_ONLY_AND_CLOSE,
         ].includes(result)
      ) {
         this.isExpanded(false);
      }
   }

   private onPaneSelectedIdsChanged(
      pane: DropDownPane<T>,
      paneSelectedIds: Observable<Set<string>>,
   ) {
      const currentSelectIds = this.selectedIds();
      const idsArray = Array.from(paneSelectedIds());
      const addedIds = Array.from(idsArray).filter((x) => !currentSelectIds.has(x));
      const idsToRemove = new Set<string>();

      for (const newId of addedIds) {
         const gridStore = pane.gridStore();
         const row = gridStore ? gridStore.rows().find((row) => row.id == newId) : null;
         if (!row) {
            idsToRemove.add(newId);
            continue;
         }
      }

      if (idsToRemove.size) {
         // Queue as a micro task to avoid a circular dependency issue caused by updating the
         // observable within a subscription callback.
         queueMicrotask(() => {
            const updatedIds = new Set(Array.from(idsArray).filter((id) => !idsToRemove.has(id)));
            paneSelectedIds(updatedIds);
            this.selectedIds(updatedIds);
         });
      } else {
         this.selectedIds(paneSelectedIds());
      }
   }

   private onBeforeGridContentChange = (): void => {
      this.contentResizeTrigger(Date.now());
   };

   private onPaneBackClicked = (): void => {
      const index = this.activePaneIndex();
      if (!index) return;
      this.transition(Transition.BACKWARD);
      this.panes().splice(index, 1);
      this.activePaneIndex(index - 1);
   };

   private onBeforeActivePaneIndexChanged(activePaneIndex: number | null) {
      if (activePaneIndex == null) {
         // Only update the inverted state when expanding the dropdown.
         this.checkViewportTrigger(Date.now());
      }
   }

   private onActivePaneIndexChanged(activePaneIndex: number | null) {
      if (activePaneIndex == null) {
         this.isButtonFocused(true);
      }
   }

   private onViewportBoundsChange() {
      this.calculateMaxHeightAndInversion();

      // Schedule another update since the position of dropdowns is often dependant the layout
      // of other elements that respond to viewport changes (ex: popups).
      if (this.recalculationTimer) clearTimeout(this.recalculationTimer);
      this.recalculationTimer = setTimeout(() => {
         this.checkViewportTrigger(Date.now());
         this.calculateMaxHeightAndInversion();
      }, 50);
   }

   private onSelectedItemChanged(selectedItem: T | null) {
      const selectedIds = this.selectedIds();
      // Clear selected IDs if necessary.
      if (selectedItem == null) {
         if (this.selectedIds().size) {
            this.selectedIds(new Set());
         }
         return;
      }
      if (selectedIds.size != 0 || Array.from(selectedIds)[0] != selectedItem.id) {
         this.selectedIds(new Set([selectedItem.id]));
      }
   }

   private onSelectedIdsChanged(selectedIds: Set<string>) {
      // Keep the selectedIds and selectedItem values synced.
      this.updateSelectedItem();

      // Keep the active pane in sync.
      this.paneComponents().forEach((paneComponent) => {
         if (!isEqualSets(selectedIds, paneComponent.params.selectedIds())) {
            paneComponent.params.selectedIds(selectedIds);
         }
      });
   }

   private onActiveRowsChanged() {
      this.updateSelectedItem();
   }

   private updateSelectedItem() {
      if (this.selectedIds().size == 1) {
         const [firstId] = this.selectedIds();
         if (this.selectedItem()?.id != firstId) {
            const item = this.activeRows().find((row) => row.id == firstId) || null;
            if (item) {
               this.selectedItem(item);
            }
         }
      } else {
         this.selectedItem(null);
      }
   }

   private calculateMaxHeightAndInversion() {
      if (this.isInvertible) {
         this.isInverted(false);
         return;
      }
      const viewport = this.viewport();
      const anchor = this.viewportBounds();
      const size = {
         height: DEFAULT_MAX_DROP_DOWN_HEIGHT,
         width: anchor.width,
      };
      const bottomBounds = findAnchoredBounds({
         anchor,
         size,
         position: AnchoredPosition.BELOW_CENTER,
      });
      // Disable inversion if the dropdown can be fully visible below.
      if (contains(viewport, bottomBounds)) {
         this.isInverted(false);
         this.maxDropDownHeight(DEFAULT_MAX_DROP_DOWN_HEIGHT);
         return;
      }

      const topBounds = findAnchoredBounds({
         anchor,
         size,
         position: AnchoredPosition.ABOVE_CENTER,
      });

      // Enable inversion if the dropdown can be fully visible above.
      if (contains(viewport, topBounds)) {
         this.isInverted(true);
         this.maxDropDownHeight(DEFAULT_MAX_DROP_DOWN_HEIGHT);
         return;
      }

      // Determine the inversion based on whether the top or bottom has more space.
      // TODO: Use these intersection to actually shrink the max height of the drop down.
      const bottomIntersection = findIntersection(viewport, bottomBounds);
      const topIntersection = findIntersection(viewport, topBounds);

      this.isInverted((topIntersection?.height ?? 0) > (bottomIntersection?.height ?? 0));

      this.maxDropDownHeight(
         Math.max(
            topIntersection?.height ?? 0,
            bottomIntersection?.height ?? 0,
            MINIMUM_MAX_DROP_DOWN_HEIGHT,
         ),
      );
   }
}

ko.components.register("drop-down-2", {
   viewModel: DropDown2,
   template: template(),
   synchronous: true,
});
