import "./toolbar-button.styl"
import template from "./toolbar-button.pug"
import { ControlViewModel } from "@/lib/vm/control-viewmodel"

export class ToolbarButton extends ControlViewModel
   constructor: (options, order, callback) ->
      assertArgs(arguments, Object, Number, optional(Function))
      super(template(), order)
      # No need to use observables, the button should be static.
      @icon = options.icon or ""
      @style = options.style or ToolbarButton.STYLE.WHITE
      @isVisible(if options.isVisible == undefined then true else options.isVisible)
      @popup = options.popup or false
      @callback = callback
      @classes = ko.pureComputed =>
         cssClasses = [@style]
         cssClasses.push(@icon) if @icon
         cssClasses.push("toolbar-btn--selected") if ko.unwrap(@isSelected)
         return cssClasses.join(" ")

   clicked: ->
      @callback() if @callback?

ToolbarButton.STYLE = {
   WHITE: "toolbar-btn--white"
   BLUE: "toolbar-btn--blue"
}

ToolbarButton.ICONS = {
   MENU: "icon-toolbar-menu"
   NEW_WHITE: "icon-plus-white"
   BACK: "icon-back-nav"
}

ToolbarButton.LOCATION = {
   LEFT: "toolbar-left"
   RIGHT: "toolbar-right"
}
