import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type {
   GetEntitiesMutualGroupsRequest,
   GetEntitiesMutualGroupsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/groups/get-entities-mutual-groups";
import type {
   FindGroupsPaginatedQueryParams,
   FindGroupsPaginatedResponse,
   FindGroupsStreamQueryParams,
} from "@laborchart-modules/lc-core-api/dist/api/groups/find-groups";
import type { GetGroupResponse } from "@laborchart-modules/lc-core-api/dist/api/groups/get-group";
import type {
   CreateGroupPayload,
   CreateGroupResponse,
} from "@laborchart-modules/lc-core-api/dist/api/groups/create-group";
import type {
   UpdateGroupPayload,
   UpdateGroupResponse,
} from "@laborchart-modules/lc-core-api/dist/api/groups/update-group";
import type { DeleteGroupResponse } from "@laborchart-modules/lc-core-api/dist/api/groups/delete-group";
import type { SerializedGroup } from "@laborchart-modules/common/dist/rethink/serializers/group-serializer";

export abstract class GroupStore extends Store {
   static createGroup(group: CreateGroupPayload): StoreJsonResponse<CreateGroupResponse> {
      return this.requestJson({
         url: "/api/v3/groups",
         method: "POST",
         body: group,
      });
   }

   static updateGroup(
      groupId: string,
      update: UpdateGroupPayload,
   ): StoreJsonResponse<UpdateGroupResponse> {
      return this.requestJson({
         url: `/api/v3/groups/${groupId}`,
         method: "PATCH",
         body: update,
      });
   }

   static deleteGroup(groupId: string): StoreJsonResponse<DeleteGroupResponse> {
      return this.requestJson({
         url: `/api/v3/groups/${groupId}`,
         method: "DELETE",
      });
   }

   static getEntitiesMutalGroups(
      query: GetEntitiesMutualGroupsRequest,
   ): StoreJsonResponse<GetEntitiesMutualGroupsResponse> {
      return this.requestJson({
         url: `/api/v3/mutual-groups`,
         query,
      });
   }

   static findGroupsPaginated(
      query: FindGroupsPaginatedQueryParams,
   ): StoreJsonResponse<FindGroupsPaginatedResponse> {
      return this.requestJson({
         method: "GET",
         query,
         url: "/api/v3/groups",
      });
   }

   static findGroupsSettingsPaginated(
      query: FindGroupsPaginatedQueryParams,
   ): StoreJsonResponse<FindGroupsPaginatedResponse> {
      return this.requestJson({
         method: "GET",
         query,
         url: "/api/v3/settings/groups",
      });
   }

   static findGroupsStream(
      query?: FindGroupsStreamQueryParams,
   ): StoreStreamResponse<SerializedGroup> {
      return this.requestStream({
         method: "GET",
         query,
         url: "/api/v3/groups/stream",
      });
   }

   static findGroupsSettingsStream(
      query: FindGroupsStreamQueryParams,
   ): StoreStreamResponse<SerializedGroup> {
      return this.requestStream({
         method: "GET",
         query,
         url: "/api/v3/settings/groups/stream",
      });
   }

   static getGroup(groupId: string): StoreJsonResponse<GetGroupResponse> {
      return this.requestJson({
         method: "GET",
         url: `/api/v3/groups/${groupId}`,
      });
   }
}
