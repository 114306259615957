import { NotificationContentFormatter } from "@/lib/utils/notification-content-formatter"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"

### Models ###
import { Model } from "@/models/model"

### Framework Includes ###
import ko from "knockout"

export class Notification extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.author_name, Object)
         assertOfType(data.category, String)
         assertOfType(data.subject_1_name, [String, Object])
         assertOfType(data.subject_2_name, optional([String, Object]))
         assertOfType(data.subject_3_name, optional([String, Object]))
         assertOfType(data.meta, optional(Object))
         assertOfType(data.subject_1_type, optional(String))
         assertOfType(data.subject_1_id, optional(String))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @category = ko.observable(data.category)
      @createdAt = ko.observable(data.created_at)
      @processedAt = ko.observable(data.processed_at)
      @sentAt = ko.observable(data.sent_at)
      
      @action = ko.observable(data.action)
      @subject1Name = ko.observable(data.subject_1_name)
      @subject2Name = ko.observable(data.subject_2_name)
      @subject3Name = ko.observable(data.subject_3_name)
      @meta = ko.observable(data.meta)
      @details = ko.observable(data.details)

      @subject1Type = ko.observable(data.subject_1_type)
      @subject1Id = ko.observable(data.subject_1_id)
      @subject2Id = ko.observable(data.subject_2_id)
      @entity = ko.observable(data.entity)
      @viewedByIds = ko.observableArray(data.viewed_by_ids)
      @viewed = ko.observable(if data.viewed_by_ids? then data.viewed_by_ids.indexOf(authManager.authedUserId()) != -1 else false)

      @subject = NotificationContentFormatter.FormatSubject(@)
      @content = NotificationContentFormatter.FormatContent(@)

      @authorName = ko.observable("#{data.author_name.first} #{data.author_name.last}")


Notification.Category = {
   PERSON_EXISTENCE_CHANGED: "person-existence-changed"
   PERSON_GROUPS_CHANGED: "person-groups-changed"
   PERSON_STATUS_CHANGED: "person-status-changed"
   PERSON_TAG_WARNING: "person-tag-warning"
   PERSON_TAG_EXPIRED: "person-tag-expired"
   PROJECT_EXISTENCE_CHANGED: "project-existence-changed"
   PROJECT_GROUPS_CHANGED: "project-groups-changed"
   PROJECT_STATUS_CHANGED: "project-status-changed"
   ASSIGNED_AS_PROJECT_ROLE: "assigned-as-project-role"
   ASSIGNMENT_CREATED: "assignment-created"
   ASSIGNMENT_EDITED: "assignment-edited"
   ASSIGNMENT_DELETED: "assignment-deleted"
   ASSIGNMENT_STARTING: "assignment-starting"
   ASSIGNMENT_ENDING: "assignment-ending"
   REQUEST_CREATED: "request-created"
   REQUEST_EDITED: "request-edited"
   REQUEST_FILLED: "request-filled"
   REQUEST_DELETED: "request-deleted"
   REQUEST_STARTING: "request-starting"
   REQUEST_ENDING: "request-ending"
}

Notification.Entity = {
   ASSIGNMENT: "assignment"
   REQUEST: "request"
   PERSON: "person"
   PROJECT: "project"
}

Notification.Status = {
   PENDING: "pending"
   SENDING: "sending"
   SENT: "sent"
   FAILED: "failed"
}

Notification.Action = {
   CREATE: "create"
   UPDATE: "update"
   DELETE: "delete"
   WARNING: "warning"
}
