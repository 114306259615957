import type { DropDownPane, ItemBase, LoadItemsParams } from "../drop-down-pane";
import { PersonStore } from "@/stores/person-store.core";
import type { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import type { SerializedPerson } from "@laborchart-modules/common/dist/rethink/serializers";
import type { KeysetGridStoreParams, ResponsePayload } from "../../grid/keyset-grid-store";
import { KeysetGridStore } from "../../grid/keyset-grid-store";
import type { StoreStreamResponse } from "@/stores/common";
import { observable } from "knockout";
import type { FindPeoplePaginatedQueryParams } from "@laborchart-modules/lc-core-api/dist/api/people/find-people";

export type SerializedPersonTransformer<T extends ItemBase> = (person: SerializedPerson) => T;

/** `DropDownPane` backed by people. */
export class PersonDropDownPane<T extends ItemBase = SerializedPerson> implements DropDownPane<T> {
   // TODO: Check on this one
   readonly gridStore = observable<any>(null);
   readonly isSearchable = observable(true);

   private readonly transformer: (person: SerializedPerson) => T;
   private readonly status: PersonStatus | null;
   private readonly groupIds: Set<string> | null;
   private readonly isAssignable: boolean | null;
   private readonly isUser: boolean | null;

   constructor({
      transformer,
      status = null,
      groupIds = null,
      isAssignable = null,
      isUser = null,
   }: {
      transformer: (person: SerializedPerson) => T;
      status?: PersonStatus | null;
      groupIds?: Set<string> | null;
      isAssignable?: boolean | null;
      isUser?: boolean | null;
   }) {
      this.transformer = transformer;
      this.status = status;
      this.groupIds = groupIds;
      this.isAssignable = isAssignable;
      this.isUser = isUser;
   }

   loadItems(params: LoadItemsParams): void {
      this.gridStore(this.createGridStore(params));
   }

   private createGridStore(params: LoadItemsParams) {
      return new PersonGridStore({
         queryParams: {
            limit: 20,
            group_ids: this.groupIds != undefined ? [...this.groupIds] : undefined,
            status: this.status || undefined,
            name: params.search || undefined,
            is_assignable: this.isAssignable || undefined,
            is_user: this.isUser || undefined,
         },
         startingCursor: params.startingAt ? { startingAt: params.startingAt } : null,
         transformer: this.transformer,
      });
   }

   static create(
      params: {
         status?: PersonStatus | null;
         isAssignable?: boolean | null;
         isUser?: boolean | null;
         groupIds?: Set<string> | null;
      } = {},
   ): PersonDropDownPane<SerializedPerson> {
      return new PersonDropDownPane({
         ...params,
         transformer: (person) => person,
      });
   }
}

interface PersonGridStoreParams<T extends ItemBase>
   extends KeysetGridStoreParams<FindPeoplePaginatedQueryParams> {
   transformer: SerializedPersonTransformer<T>;
}

class PersonGridStore<T extends ItemBase = SerializedPerson> extends KeysetGridStore<
   T,
   FindPeoplePaginatedQueryParams
> {
   private readonly transformer: SerializedPersonTransformer<T>;

   constructor(params: PersonGridStoreParams<T>) {
      super(params);
      this.transformer = params.transformer;
   }

   async loadRows(queryParams: FindPeoplePaginatedQueryParams): Promise<ResponsePayload<T>> {
      const payload = await PersonStore.findPeople(queryParams).payload;
      return {
         data: payload.data.map(this.transformer),
         pagination: payload.pagination,
      };
   }

   createLoadAllRowsStream(): StoreStreamResponse<T> {
      throw new Error("Not implemented.");
   }
}
