import { Model } from "@/models/model"
import ko from "knockout"

export class Group extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.timezone, String)
         assertOfType(data.color, nullable(String))
         assertOfType(data.pic_url, nullable(String))
         assertOfType(data.icon_name, nullable(String))
         assertOfType(data.address_1, optional(String))
         assertOfType(data.address_2, optional(String))
         assertOfType(data.city_town, optional(String))
         assertOfType(data.state_province, optional(String))
         assertOfType(data.zipcode, optional(String))
         assertOfType(data.country, optional(String))
         assertOfType(data.contact_name, optional(String))
         assertOfType(data.contact_phone, optional(String))
         assertOfType(data.contact_email, optional(String))

      ###------------------------------------
         Model ID & Meta
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @companyId = ko.observable(data.company_id)
      @name = ko.observable(data.name)
      @timezone = ko.observable(data.timezone)
      @color = ko.observable(data.color)
      @picUrl = ko.observable(data.pic_url)
      @iconName = ko.observable(data.icon_name)
      # TODO: Clean up these nulls. tmp solution to optional data.
      @address1 = ko.observable(data.address_1 or null)
      @address2 = ko.observable(data.address_2 or null)
      @cityTown = ko.observable(data.city_town or null)
      @stateProvince = ko.observable(data.state_province or null)
      @zipcode = ko.observable(data.zipcode or null)
      @country = ko.observable(data.country or null)
      @contactName = ko.observable(data.contact_name or null)
      @contactPhone = ko.observable(data.contact_phone or null)
      @contactEmail = ko.observable(data.contact_email or null)

      @iconComponent = ko.pureComputed(() =>
         iconName = @iconName()
         if !iconName? || iconName.length == 0 || ko.components.isRegistered(iconName) != true
            return null
         return {
            name: iconName
            params: {}
         }
      )

