import { authManager } from "@/lib/managers/auth-manager"

### Framework Includes ###
import ko from "knockout"
import Faye from "../../vendor/faye-browser-min"

export class FanoutMessage
   constructor: (data) ->
      assertArgs(arguments, Object)
      assertOfType(data.subscription_url, String)
      assertOfType(data.timestamp, Number)
      assertOfType(data.entity_id, optional(String))
      assertOfType(data.parent_entity_id, optional(String))
      assertOfType(data.data, optional(Object))

      @subscriptionUrl = data.subscription_url
      @timestamp = data.timestamp
      @entityId = data.entity_id or null
      @parentEntityId = data.parent_entity_id or null
      @data = data.data
      @userId = data.user_id ? null

export class FanoutManager
   constructor: () ->
      @client = new Faye.Client(config.LC_FANOUT_URL)
      @companyId = authManager.companyId
      @userId = ko.pureComputed =>
         return if authManager.authedUser()? then authManager.authedUser().id else null
      @groupId = authManager.selectedGroupId
      @activeSubscriptions = {}
      @activeListeners = {}

   getSubscription: (listenerData, listenerNamespace, channel, entityData, callback) =>
      [listenerData, listenerNamespace, channel, entityData, callback] = assertArgs(arguments, optional(Object), String, Object, optional(Object), Function)
      # Construct full subscription path.
      subscriptionUrl = @buildSubscriptionUrl_(channel, entityData)
      # Check if channel is open
      if subscriptionUrl of @activeSubscriptions
         # Check if the listener is already attached
         ### eslint-disable no-empty ###
         if listenerNamespace of @activeListeners[subscriptionUrl]
            return
         else
            # Add listener
            return @addListener_(listenerData, listenerNamespace, subscriptionUrl, callback)
         ### eslint-enable no-empty ###
      else
         # Open channel
         return @openChannel_(listenerData, listenerNamespace, subscriptionUrl, callback)

   addListener_: (listenerData, listenerNamespace, subscriptionUrl, callback) =>
      [listenerData, listenerNamespace, subscriptionUrl, callback] = assertArgs(arguments, optional(Object), String, String, Function)
      data = {callback: callback}
      data['listenerData'] = listenerData if listenerData?
      return @activeListeners[subscriptionUrl][listenerNamespace] = data

   openChannel_: (listenerData, listenerNamespace, subscriptionUrl, callback) =>
      [listenerData, listenerNamespace, subscriptionUrl, callback] = assertArgs(arguments, optional(Object), String, String, Function)
      
      @activeSubscriptions[subscriptionUrl] = @client.subscribe subscriptionUrl, (message) =>
         json = if message instanceof Object then message else JSON.parse(message)
         message = new FanoutMessage(json)
         formatedUrl = "/#{message.subscriptionUrl}"
         for name, data of @activeListeners[formatedUrl]
            if data.listenerData?
               data.callback(message, data.listenerData)
            else
               data.callback(message, null)

      @activeListeners[subscriptionUrl] = {}
      data = {callback: callback}
      data['listenerData'] = listenerData if listenerData?
      @activeListeners[subscriptionUrl][listenerNamespace] = data
      return

   buildSubscriptionUrl_: (channel, entityData) =>
      assertArgs(arguments, Object, optional(Object))
      structuredPath = ""
      # User Paths
      if channel.structure.inUser
         userId = if entityData?.userId? then entityData.userId else @userId()
         structuredPath += "/users/#{userId}"
      # Group Paths
      if channel.structure.inGroup
         groupId = if entityData?.groupId? then entityData.groupId else @groupId()
         structuredPath += "/groups/#{groupId}"
      # Project Paths
      if channel.structure.inProject
         assertOfType(entityData.projectId, String)
         structuredPath += "/projects/#{entityData.projectId}"
      # Person Paths
      if channel.structure.inPerson
         assertOfType(entityData.personId, String)
         structuredPath += "/people/#{entityData.personId}"
      # Cost Code Paths
      if channel.structure.inCostCode
         assertOfType(entityData.costCodeId, String)
         structuredPath += "/cost-codes/#{entityData.costCodeId}"
      # Generated Reports Paths
      if channel.structure.inGeneratedReport
         assertOfType(entityData.generatedReportId, String)
         structuredPath += "/generated-reports/#{entityData.generatedReportId}"
      return "/companies/#{@companyId()}#{structuredPath}#{channel.path}"

   getSubscriptionExistence: (listenerNamespace, channel, entityData) =>
      assertArgs(arguments, String, Object, optional(Object))
      subscriptionUrl = @buildSubscriptionUrl_(channel, entityData)
      return @activeListeners[subscriptionUrl]?[listenerNamespace]?

   unsubscribe: (listenerNamespace, channel, entityData, callback) =>
      [listenerNamespace, channel, entityData, callback] = assertArgs(arguments, String, Object, optional(Object), optional(Function))
      subscriptionUrl = @buildSubscriptionUrl_(channel, entityData)
      if @activeListeners[subscriptionUrl]?[listenerNamespace]? and Object.keys(@activeListeners[subscriptionUrl]).length == 1
         # Cancel subscription
         @activeSubscriptions[subscriptionUrl].cancel()
         delete @activeSubscriptions[subscriptionUrl]
         delete @activeListeners[subscriptionUrl]
         return callback(null, true) if callback?
      else if @activeListeners[subscriptionUrl]?[listenerNamespace]?
         delete @activeListeners[subscriptionUrl][listenerNamespace]
         return callback(null, true) if callback?
      else
         console.log "failed to unsubscribe from subscription"
         return callback(FanoutManager.Errors.NO_SUBSCRIPTION) if callback?

   breakGroupsSubscriptions: (groupId) =>
      subscriptionUrlsToBreak = []
      for key, val of @activeSubscriptions
         url = key.split("/")
         groupIdKey = url.indexOf("groups") + 1
         continue unless url[groupIdKey]? and url[groupIdKey] == groupId
         val.cancel()
         subscriptionUrlsToBreak.push(key)

      for url in subscriptionUrlsToBreak
         delete @activeSubscriptions[url]
         delete @activeListeners[url]

   updateListenerData: (listenerData, listenerNamespace, channel, entityData, callback) =>
      [listenerData, listenerNamespace, channel, entityData, callback] = assertArgs(arguments, Object, String, Object, optional(Object), Function)
      subscriptionUrl = @buildSubscriptionUrl_(channel, entityData)
      @activeListeners[subscriptionUrl][listenerNamespace].listenerData = listenerData
      callback(null, true)

   getListenerData: (listenerNamespace, channel, entityData) =>
      [listenerNamespace, channel, entityData] = assertArgs(arguments, String, Object, optional(Object))
      subscriptionUrl = @buildSubscriptionUrl_(channel, entityData)
      listenerData  = @activeListeners[subscriptionUrl]?[listenerNamespace]?.listenerData or null
      return listenerData

FanoutManager.Channel = {
   # Entities
   ASSIGNMENTS: {path:"/assignments", structure: {inProject: true, inCostCode: true}}
   COMPANY_ACTIVITY: {path: "/activity", structure: {}}
   CONVERSATIONS: {path: "/conversations", structure: {inUser: true}}
   COST_CODES: {path:"/cost-codes", structure: {inGroup: true, inProject: true}}
   GENERATED_REPORT: {path: "", structure: {inGroup: true, inGeneratedReport: true}}
   GENERATED_REPORTS: {path: "/generated-reports", structure: {inGroup: true}}
   GROUP_PLACEHOLDERS: {path: "/placeholders", structure: {inGroup: true}}
   GROUPS_ASSIGNMENTS: {path: "/assignments", structure: {inGroup: true}}
   GROUPS_REQUESTS: {path: "/requests", structure: {inGroup: true}}
   GROUPS: {path: "/groups", structure: {}}
   MESSAGES: {path: "/messages", structure: {inPerson: true}}
   PEOPLE_ASSIGNMENTS: {path: "/people/assignments", structure: {}}
   PEOPLE_OFF: {path: "/people/off", structure: {inGroup: true}}
   PEOPLE: {path: "/people", structure: {inGroup: true}}
   PERSON_ACTIVITY: {path: "/activity", structure: {inPerson: true}}
   POSITIONS: {path: "/positions", structure: {}}
   PROJECT_ACTIVITY: {path: "/activity", structure: {inProject: true}}
   PROJECT_META: {path: "/meta", structure: {inProject: true}}
   PROJECT_PLACEHOLDERS: {path: "/placeholders", structure: {inProject: true}}
   PROJECTS: {path: "/projects", structure: {inGroup: true}}
   SAVED_VIEWS: {path: "/saved-views", structure: {}}
   TAGS: {path: "/tags", structure: {inGroup: true}}
   TOOLS: {path: "/tools", structure: {inGroup: true}}
   USERS_NOTIFICATIONS: {path: "/notifications", structure: {inUser: true}}
}

FanoutManager.Errors = {
   NO_SUBSCRIPTION: "No such subscription for namespace/channel combo"
   CREATE_SUBSCRIPTION_FAILED: "Failed to create new subscription"
   WAS_NOT_LISTENER: "The provided namespace was not a listener for that channel"
}

# No singleton declared becuase a env var is required for instantiation.
export fanoutManager = new FanoutManager()