export function isEqualSets<T>(a: Set<T>, b: Set<T>): boolean {
   if (a.size != b.size) return false;
   for (const element of a) {
      if (!b.has(element)) return false;
   }
   return true;
}

export function getSetIntersection<T>(a: Set<T>, b: Set<T>): Set<T> {
   const intersection = new Set<T>();
   a.forEach((elem) => {
      if (b.has(elem)) intersection.add(elem);
   });
   return intersection;
}

export function getSetUnion<T>(a: Set<T>, b: Set<T>): Set<T> {
   return new Set([...a, ...b]);
}

export function getSetDifference<T>(from: Set<T>, subtract: Set<T>): Set<T> {
   const difference = new Set<T>();
   from.forEach((elem) => {
      if (!subtract.has(elem)) difference.add(elem);
   });
   return difference;
}
